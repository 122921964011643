.App {
  text-align: center;
}


header {
  margin-left: 0 !important;
  padding-top: 0 !important;
  padding-left: 0 !important;
  padding-bottom: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  margin-right: 0 !important;
  padding-right: 0 !important;
  transition: height .2s ease, background-color .2s ease, padding .2s ease, width .2s ease, margin .2s ease, top .2s ease, transform .2s ease;
  float: none;
}

header .header-wrapper {
  position: fixed !important;
  z-index: 101;
  width: 100%;
  min-width: 768px;
  background-color: rgba(17, 17, 17, 0.55) !important;
}

.page-wrapper {
  background-color: rgba(244, 244, 244, 1) !important;
}

/* Mobile only */
@media (max-width: 1050px) {
  .page-wrapper section {
    padding: 0% 5% !important;
  }

  .page-wrapper .page-title {
    padding: 0% 5% !important;
    width: 90%;
  }
}


@media (max-width: 785px) {
  .page-title {
  
    h1 {
      font-size: 40px !important;
    }
  
  }

}

@media (max-width: 350px) {
  .page-title {
  
    h1 {
      font-size: 33px !important;
    }
  
  }
}

.page-title {
  width: 100%;
  color: #750b14;
  text-align: left;
  max-width: 960px;
  margin: 0 auto;

  h1 {
    margin: 0;
    padding-top: 2rem;
    padding-bottom: 1rem;
    font-size: 48px;
    font-family: "Titillium Web", "Titillium Web Fallback";
    text-transform: uppercase !important;
  }

  hr {
    color: rgb(99, 97, 96) !important;
  }
}

