.staff-section-wrapper.childcare {
    background-color: inherit !important;
    flex-wrap: wrap;
    font-family: "Titillium Web", "Titillium Web Fallback";
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
}

.staff-section-wrapper.childcare .childcare-staff-heading-wrapper {
    h2 {
        margin: 0;
    }
    
    p {
        margin: 0;
    }
}


.staff-section-wrapper.childcare .childcare-staff-header {
    color: rgba(72, 65, 65, 1) !important;
}

.staff-section-wrapper.childcare .info-line {
    
    p {
        margin: 0;
    }

    h3 {
        text-decoration: underline;
        text-underline-offset: 3px;
        
        color: #9e1521 !important;
    }
}

.staff-section-wrapper.childcare .staff-member-image,
.staff-section-wrapper.admin-childcare .staff-member-image {

    i {
        font-size: 5rem;
        padding: 0.5rem;
        color: #750b14 !important; 
    }
}


