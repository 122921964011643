.page-wrapper .page-content-section .section-content-wrapper .contact-info-wrapper {
    padding-top: 50px !important;
    padding-left: 0 !important;
    padding-bottom: 0 !important;
    padding-right: 0 !important;
    margin-left: 0px !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    margin-right: 35px !important;
    background-color: rgba(255, 255, 255, 1) !important;
    box-shadow: rgba(0, 0, 0, 0.25) 0 3px 11px 0 !important;
    position: relative;
    width: 50%;
    display: inline-block;
    vertical-align: top;
    box-sizing: border-box;
}




.page-wrapper .page-content-section .section-content-wrapper .contact-info-wrapper .text-block-wrapper:nth-child(2) {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem  !important;
}

.page-wrapper .page-content-section .section-content-wrapper .contact-info-wrapper .text-block-wrapper {
    padding-top: 0 !important;
    padding-left: 0 !important;
    padding-bottom: 0 !important;
    padding-right: 0 !important;
    float: none !important;
    top: 0 !important;
    left: 0 !important;
    width: auto !important;
    position: relative !important;
    height: auto !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
    max-width: 100% !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    min-width: 0 !important;
    text-align: center !important;

    h2 {
        color: #a70f1c;
        direction: ltr;
        font-family: "Titillium Web", "Titillium Web Fallback";
        font-weight: 700;
        font-style: normal;
        text-decoration: none;
        margin-left: 0 !important;
        padding-top: 2px !important;
        padding-left: 0 !important;
        padding-bottom: 2px !important;
        margin-top: 0 !important;
        margin-bottom: 0px !important;
        margin-right: 0 !important;
        padding-right: 0 !important;
        font-size: 30px;
        text-align: center !important;
    }

    a {
        color: #a70f1c;
        transition: color 0.15s ease-out;

        &:hover {
            color: black;
            font-weight: bold;
        }
    }
}

.page-wrapper .page-content-section .section-content-wrapper .contact-info-wrapper .text-block-wrapper .text-block {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 0px;
    padding-right: 0px;

    p {
        color: rgba(72, 65, 65, 1);
        font-family: "Titillium Web", "Titillium Web Fallback";
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        unicode-bidi: isolate;
        font-weight: 600;
        font-size: 16px;
        transition: font-weight 0.2s ease-out;
    }
}

.captcha-wrapper {
    display: flex;
    position: relative;
    justify-content: center;
    z-index: 90;
    margin-left: 1rem;
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
}

.captcha-scale {
    transform: scale(0.95);
    transform-origin: center;
    justify-content: center !important;
    align-items: center !important;
    display: flex;
}

.page-wrapper .page-content-section .section-content-wrapper .contact-form-wrapper {
    opacity: 1;
    transform: translate3d(0%, 0%, 0px);
    visibility: visible !important;
    transition-delay: unset !important;
    transition-duration: unset !important;
    animation-delay: unset !important;
    animation-duration: unset !important;
    transition-property: none !important;
    padding-right: 20px !important;
    margin-left: 0 !important;
    padding-top: 0 !important;
    padding-left: 0 !important;
    padding-bottom: 10px !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    margin-right: 0 !important;

    h2 {
        color: #750b14;
        direction: ltr;
        font-family: "Titillium Web", "Titillium Web Fallback";
        font-weight: 700;
        font-style: normal;
        text-decoration: none;
        margin-left: 0 !important;
        padding-top: 2px !important;
        padding-left: 0 !important;
        padding-bottom: 2px !important;
        margin-top: 0 !important;
        margin-bottom: 8px !important;
        margin-right: 0 !important;
        padding-right: 0 !important;
        text-align: left;
        font-size: 30px;
    }
}


/* Mobile only */
@media (max-width: 865px) {
    .page-wrapper .page-content-section .section-content-wrapper .contact-info-wrapper .text-block-wrapper{
        padding: 0 5% !important;
        text-wrap: wrap;
        word-wrap: break-word;

    }
}

/* Mobile only */
@media (max-width: 785px) {

    .page-wrapper .page-content-section {
        padding: 5% !important;
    }

    .page-wrapper .page-content-section .section-content-wrapper {
        flex-direction: column;
        gap: 2rem;
    }

    .page-wrapper .page-content-section .section-content-wrapper .section-text-content {
        width: 100% !important;
        
    }

    .page-wrapper .page-content-section .section-content-wrapper .contact-info-wrapper,
    .page-wrapper .page-content-section .section-content-wrapper .contact-form-wrapper {
        width: 100% !important;
        margin: 0 !important;
        padding: 0 !important;


        .contact-form-container .contact-form form {
            width: 100% !important;
        }
        
    }


}

/* Mobile only */
@media (max-width: 630px) {
    .page-wrapper .church-directory-form-section.visible {
        max-height: fit-content !important;
    }
}

.page-wrapper .page-content-section .section-content-wrapper .contact-form-wrapper .contact-form-container,
.page-wrapper .page-content-section .section-content-wrapper .contact-form-wrapper .contact-form-container .contact-form
 {
    margin: 0;
    padding: 0;

    form {
        max-width: 960px;
        position: relative;
        margin: 0 auto;
        width: 100%;
        padding-top: 15px;
        padding-bottom: 15px;
    }
}

.page-wrapper .page-content-section .section-content-wrapper .contact-form-wrapper .contact-form-container .contact-form .input-wrapper {
    margin-top: 20px;
    position: relative;
    width: 100%;
    display: inline-block;
    vertical-align: top;
    padding-left: 0.9375em;
    padding-right: 0.9375em;
    box-sizing: border-box;
    unicode-bidi: isolate;
    line-height: 150%;
    font-size: 16px;
    word-wrap: break-word;

    label {
        font-size: 13px !important;
        font-weight: normal !important;
        color: black;
        font-family: "Titillium Web", "Titillium Web Fallback";
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        box-sizing: border-box;
        cursor: default;
        text-align: left;
        word-wrap: break-word;
    }

    input, textarea {
        background: 0;
        border: 0;
        border-radius: 0;
        border-bottom: 1px solid;
        height: 30px;
        border-bottom-color: rgb(189, 189, 189) !important;
        background-color: white;
        padding: 0 10px;
        width: 100%;
        margin: 3px 0;
        line-height: normal;
        font-family: inherit;
        transition: border-color 0.2s ease, border-width 0.1s ease-in;
        text-transform: none;
        text-indent: 0px;
        text-shadow: none;
        display: inline-block;
        text-align: start;
        text-rendering: auto;
        letter-spacing: normal;
        word-spacing: normal;

        &:hover {
            border-bottom-color: rgb(0, 0, 0) !important;
        }

        &:focus {
            border-width: 4px;
            border-bottom-color: rgb(0, 0, 0) !important;
        }
    }

    textarea {
        width: 100% !important;
        margin: 3px 0;
        padding: 10px;
        min-height: 50px;
        font-size: 16px;
        
    }
}

.page-wrapper .page-content-section .section-content-wrapper .contact-form-wrapper .contact-form-container .contact-form .submit-button-wrapper {
    padding-left: 0.9375em; 
}

.submit-button-wrapper button:disabled {
    cursor: not-allowed !important
}

.page-wrapper .page-content-section .section-content-wrapper .contact-form-wrapper .contact-form-container .contact-form .submit-button-wrapper button:disabled {
    color: #ffffffbd !important;
    background-color: #9e1520bd !important;
}

.page-wrapper .page-content-section .section-content-wrapper .contact-form-wrapper .contact-form-container .contact-form .submit-button-wrapper button {

    color: white;
    background-color: #9e1521;

    border-radius: 0;
    margin: auto !important;
    display: inline-block;
    line-height: 1.2; /* Adjusted for better text wrapping */
    cursor: pointer;
    box-shadow: none;
    background-image: none;
    border-style: solid;
    white-space: normal; /* Allows text to wrap */
    transition: background-color 0.2s ease, border-color 0.2s ease;
    clear: both;
    position: relative;
    text-decoration: none;
    border: none;
    padding: 12px 5%; /* Responsive padding */
    font-weight: 700;
    font-size: clamp(16px, 1.5vw, 18px); /* Responsive font size */
    font-family: "Titillium Web", "Titillium Web Fallback";
    margin-bottom: 2rem !important;
    margin-top: 0rem !important;
    max-width: 90%; /* Button width limited to container */

    &:hover {
        background-color: #801019;
    }

    &:focus {
        outline-offset: 8px;
    }
}

.page-wrapper .show-directory-form {
    border-radius: 0;
    margin: auto !important;
    display: inline-block;
    line-height: 1.2; /* Adjusted for better text wrapping */
    cursor: pointer;
    box-shadow: none;
    background-image: none;
    border-style: solid;
    white-space: normal; /* Allows text to wrap */
    transition: background-color 0.2s ease, border-color 0.2s ease;
    clear: both;
    position: relative;
    text-decoration: none;
    border: none;
    padding: 12px 5%; /* Responsive padding */
    color: white;
    font-weight: 700;
    font-size: clamp(16px, 1.5vw, 18px); /* Responsive font size */
    font-family: "Titillium Web", "Titillium Web Fallback";
    background-color: #1d1d1de7;
    margin-bottom: 2rem !important;
    margin-top: 0rem !important;
    max-width: 90%; /* Button width limited to container */

    &:hover {
        background-color: #3f3f3fdc;
    }

    &:focus {
        outline-offset: 5px;
    }
}


.page-wrapper .directory-button {
    border-radius: 2rem;
    margin: auto !important;
    display: inline-block;
    line-height: 1.2; /* Adjusted for better text wrapping */
    cursor: pointer;
    box-shadow: none;
    background-image: none;
    border-style: solid;
    white-space: normal; /* Allows text to wrap on smaller screens */
    transition: background-color 0.2s ease, border-color 0.2s ease;
    clear: both;
    position: relative;
    text-decoration: none;
    border: none;
    padding: 12px 5%; /* Responsive padding */
    color: rgb(247, 247, 247);
    font-weight: 700;
    font-size: clamp(16px, 1.5vw, 18px); /* Responsive font size */
    font-family: "Titillium Web", "Titillium Web Fallback";
    background-color: #9e1521;
    margin-bottom: 1rem !important;
    margin-top: 0rem !important;
    max-width: 100%; /* Constrain button width to the container */

    .go-arrow {
        opacity: 0;
        transition: opacity 0.2s ease-out, transform 0.3s ease-out;
    }

    &:hover {
        background-color: #801019;

        .go-arrow {
            opacity: 1;
            transform: translateX(0.5rem);
        }
    }

    &:focus {
        outline-offset: 5px;
    }
    
}


.church-directory-form-section {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-out, opacity 0.5s ease-out;
    animation-delay: 0.3s;
    opacity: 0;
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
    position: relative;
    
    h4 {
        font-family: "Titillium Web", "Titillium Web Fallback";
        font-size: 18px;
        color: rgba(72, 65, 65, 1);
        margin: 0;
    }
}

.church-directory-form-section.visible {
    max-height: 1100px; /* Set to a high value to ensure the content fits */
    opacity: 1;
    transition: max-height 0.5s ease-in, opacity 0.5s ease-in;
    z-index: unset;
}

.form-status.directory {
    width: 100% !important;
}

.church-directory-form-section .church-directory-form-wrapper {
    display: flex;
    font-family: "Titillium Web", "Titillium Web Fallback";
    justify-content: center;

    form {
        display: flex;
        gap: 3rem;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 3rem;
        margin-bottom: 3rem;
        max-width: 75%;
    }
}

.name-fields-wrapper {
    display: flex;
    gap: 1rem;
}

.directory-info-text {
    width: 45%;
    margin: 0 auto;
    margin-bottom: 3rem;
}

.page-wrapper .church-directory-form-section .church-directory-form-wrapper .submit-button-wrapper
 {
    width: fit-content !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.submit-button-wrapper.directory {
    display: flex !important;
    align-items: end !important;
}

.page-wrapper .church-directory-form-section .church-directory-form-wrapper .submit-button-wrapper button{
    
    border-radius: 0rem;
    margin: auto !important;
    display: inline-block;
    line-height: 22px;
    cursor: pointer;
    box-shadow: none;
    background-image: none;
    border-style: solid;
    white-space: nowrap;
    transition: background-color .2s ease, border-color .2s ease;
    clear: both;
    position: relative;
    text-decoration: none;
    border: none;
    padding: 12px 35px;
    color:white;
    font-weight: 700;
    font-size: 18px;
    font-family: "Titillium Web", "Titillium Web Fallback";
    background-color: #1d1d1de7;
    margin: 0rem !important;
    

    &:hover {
        background-color: #414141e7;
    }

    &:disabled {
        background-color: #414141a2;
    }
}




.page-wrapper .church-directory-form-section .church-directory-form-wrapper .input-wrapper {
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 0.5rem;
    justify-content: center;

    label {
        font-size: large;
    }

    input {
        width: 100%;
        margin: 3px 0;
        height: 40px;
        padding: 0 10px;
        font-size: 16px;
        line-height: normal;
        font-family: inherit;
        box-sizing: border-box;
        border: none;
        border-bottom: 1px solid;
        border-bottom-color: rgb(189, 189, 189) !important;
        background-color: white;
        transition: border-color 0.2s ease, border-width 0.1s ease-in;



        &:hover {
            border-bottom-color: rgb(0, 0, 0) !important;
        }

        &:focus {
            border-width: 3px;
            border-bottom-color: rgb(0, 0, 0) !important;
        }
    }
}


@media (max-width: 450px) {
    .name-fields-wrapper {
        flex-direction: column;
    }
}

@media (max-width: 400px) {
    .name-fields-wrapper {
        flex-direction: column;
    }

}

@media (max-width: 350px) {
    .captcha-scale {
        transform: scale(0.65);
    }

}