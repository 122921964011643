.occ-highlight {
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(158, 158, 158, 0.5);
    padding: 0rem;
    color: rgba(72, 65, 65, 1) !important;
    justify-content: flex-start !important;
    box-shadow: rgba(0, 0, 0, 0.205) 0 1px 11px 0 !important;
}

.inert {
    pointer-events: none;
    opacity: 0.5;
    user-select: none;
  }

.occ-highlight-wrapper {
    width: 31%;
}
.page-wrapper.christmaschild {
    padding-bottom: 4rem;
}

.page-wrapper.christmaschild section .section-content-wrapper .content-text-wrapper {
    padding-right: 0 !important;
}

/* Mobile only */
@media (max-width: 650px) {
    .occ-highlight-wrapper {
        width: calc(50% - 1rem);
    }

}

@media (max-width: 350px) {
    .occ-highlights-row {

        flex-direction: column;
        width: 100% !important;
        align-items: center;
        justify-content: center;
        align-items: center;
    }

    .occ-highlight-wrapper {
        width: 100%;
    }

}

.occ-highlights-row {
    display: flex;
    gap: 2rem;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
}

.occ-highlight .highlight-image-wrapper {
    
    img {
        width: 100%;
        max-height: 210px !important;
    }
    
}


.highlight-year-wrapper {
    

    h3 {
        font-size: 35px;
        margin-bottom: 0.5rem;
    }
}


.highlight-boxes-wrapper {   
    margin-bottom: 0.5rem;
    
    
    h5 {
        color: #750b14;
        font-size: 28px;
        margin-top: 0.5rem;
        margin-bottom: 0;
        
    }

    p {
        margin: 0;
        text-align: center;
        
    }
}

.highlight-desc-wrapper {
    display: flex;
    justify-content: center !important;
    margin-bottom: 1rem;

    p {
        width: 70%;
        text-align: center;
    }
}
