

/* Mobile only */
@media (max-width: 785px) {
    .page-wrapper.worship .page-content-section .section-content-wrapper {
        flex-wrap: wrap;
        flex-direction: column;
        
        .section-image-column {
            order: -1;
        }
    }

    .page-wrapper.worship .page-content-section .section-content-wrapper * {

        width: 100%;
    }
}