.page-wrapper .text-content-section .section-content-wrapper .daily-reading-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 1.5rem;
    flex-wrap: wrap;
}

.page-wrapper.dailyreadings .content-text-wrapper h2 {
    justify-content: center !important;
    display: flex;
}


/* Mobile only */
@media (max-width: 555px) {
    .page-wrapper .text-content-section .section-content-wrapper .daily-reading-wrapper {
        flex-direction: column;

        .reading-wrapper {
            width: 100% !important;
        }

        .reading-wrapper.today p {
            font-size: 24px !important;
        }
    }
}

.page-wrapper .text-content-section .section-content-wrapper .daily-reading-wrapper .reading-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: rgba(72, 65, 65, 1);

    h2 {
        color: rgba(72, 65, 65, 1) !important;
        font-family: "Titillium Web", "Titillium Web Fallback";
        font-size: 1.17rem !important;
        font-weight: 700;
        margin: 0;
    }
}

.page-wrapper .text-content-section .section-content-wrapper .daily-reading-wrapper .reading-wrapper.today {
    h2 {
        color: #a70f1c !important;
        direction: ltr;
        font-family: "Titillium Web", "Titillium Web Fallback";
        font-weight: 700;
        font-style: normal;
        text-decoration: none;
        font-size: 32px !important;
        text-align: center;
    }
}

.page-wrapper .text-content-section .section-content-wrapper .daily-reading-wrapper .reading-wrapper .todays-reading {
    background-color: rgb(255, 255, 255);
    border-radius: 1rem;
    backdrop-filter: blur(4px);
    outline: 2px solid rgba(0, 0, 0, 0.616);
    padding: 0.5rem;
    max-width: 75%;
    word-wrap: break-word;



    p {
        font-size: 36px;
        color: #750b14 !important;
        line-height: 100%;
        margin-top: 1rem;
        margin-bottom: 1rem;
        font-weight: 700;
    }

    h5 {
        font-size: 16px;
        margin-top: 0.5rem;
    }

}

.page-wrapper .text-content-section .section-content-wrapper .daily-reading-wrapper .reading-wrapper .alt-reading {
    background-color: rgb(238, 238, 238);
    border-radius: 1rem;
    backdrop-filter: blur(4px);
    outline: 2px solid rgba(0, 0, 0, 0.342);
    padding: 0.5rem;

    

    p {
        font-size: 18px;
        font-weight: 700;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
}



.page-wrapper.dailyreadings .section-content-wrapper {
    justify-content: center !important;
}

.archive-wrapper .archive-item {
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;

}