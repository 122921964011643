.navbar-wrapper {
    transition: height .2s ease, background-color .2s ease, padding .2s ease, width .2s ease, margin .2s ease, top .2s ease, transform .2s ease;
    padding-top: 0 !important;
    padding-bottom: 12px !important;
    margin-top: 0 !important;
    padding-right: 15px !important;
    padding-left: 15px !important;
    margin-left: 0 !important;
    margin-bottom: 0 !important;
    margin-right: 0 !important;
    width: auto !important;
    float: none !important;
    top: 0 !important;
    left: 0 !important;
    position: relative !important;
    height: auto !important;
    max-width: 100% !important;
    min-width: 0 !important;
    text-align: center !important;
    background-color: rgba(0, 0, 0, 0) !important;
    border-style: solid !important;
    border-width: 0 !important;
    border-color: rgba(201, 201, 201, 1) !important;
    background-repeat: no-repeat !important;
    background-image: none !important;
    background-size: cover !important;
    color: #f7f7f7;
    display: flex;
    justify-content: center;
}


.navbar-wrapper .navbar-container .navlinks-wrapper {
    margin-left: 0 !important;
    padding-top: 0 !important;
    padding-left: 0 !important;
    padding-bottom: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    margin-right: 0 !important;
    padding-right: 0 !important;
    color: rgba(72, 65, 65, 1);
    font-family: "Titillium Web", "Titillium Web Fallback";
    position: relative;
    width: 100%;
    display: inline-block;
    vertical-align: top;
    text-align: center !important;

    
}

.navbar-wrapper .navbar-container .navlinks-wrapper .navlinks {
    min-width: 9em !important;
    text-align: initial !important;
    transition: height .2s ease, background-color .2s ease, padding .2s ease, width .2s ease, margin .2s ease, top .2s ease, transform .2s ease;
    display: block !important;
    float: none !important;
    top: 0 !important;
    left: 0 !important;
    position: relative !important;
    height: auto !important;
    padding-top: 7px !important;
    padding-left: 15px !important;
    padding-bottom: 6.8px !important;
    margin-right: 123px !important;
    margin-left: 123px !important;
    margin-top: 10px !important;
    margin-bottom: 0px !important;
    padding-right: 15px !important;
    line-height: 150%;
    color: white !important;
    overflow: visible;
    font-family: "Titillium Web", "Titillium Web Fallback";
    word-wrap: break-word;
    justify-content: center;
}

.navlinks-list {
    flex-wrap: nowrap !important;
    justify-content: inherit;
    list-style-position: inside;
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 1rem;
    text-align: initial !important;
    color: white !important;
}

 .navlink-item {
    display: flex;
    justify-content: flex-start;
    position: relative;
    font-weight: normal;
    vertical-align: top;
    list-style: none;
    font-size: 1rem;
    color: white !important;
    line-height: 75%;
    font-family: "Titillium Web", "Titillium Web Fallback";
    word-wrap: break-word;



    &::before {
        content: '';
        align-self: center;
        font-weight: bold;
        box-sizing: border-box;
    }

    &::after {
        content: '';
        align-self: center;
        font-weight: bold;
        box-sizing: border-box;
    }

}

.navbar-button-wrapper.e-connections {
    display: flex;
    justify-content: flex-start;
    position: relative;
    font-weight: normal;
    vertical-align: top;
    margin-right: 0.2rem;
}

.navbar-button-wrapper.e-connections a {
    color: rgba(255, 255, 255, 1) !important;
    margin-left: 0 !important;
    padding-top: 0 !important;
    padding-left: 15px !important;
    padding-bottom: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    margin-right: 0 !important;
    padding-right: 15px !important;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    text-decoration: none;
    cursor: pointer;
    position: relative;
    font-weight: inherit;
    letter-spacing: 0.03em;
    outline: none;
    vertical-align: inherit;
    background: #ff9800d4;
    border-radius: 5px;
    border: 1px solid #ffc107;

    span {
        pointer-events: none;
        white-space: nowrap;
        font-weight: inherit;
        letter-spacing: 0.03em;
        cursor: pointer;
        font-size: 1rem;
    }
}

.navbar-button-wrapper a {
    transition: background-color 0.1s ease;
}

.navbar-button-wrapper.give a:hover {
    background: #ad6800d4 !important;
}


.navbar-button-wrapper.give {
    display: flex;
    justify-content: flex-start;
    position: relative;
    font-weight: normal;
    font-size: 1rem;
    font-family: "Titillium Web", "Titillium Web Fallback";
}

.navbar-button-wrapper a:focus {
    outline-offset: 5px;
}

.navbar-button-wrapper.give a {
    color: rgba(255, 255, 255, 1) !important;
    margin-left: 0 !important;
    padding-top: 0 !important;
    padding-left: 15px !important;
    padding-bottom: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    margin-right: 0 !important;
    padding-right: 15px !important;
    font-weight: inherit;
    letter-spacing: 0.03em;
    text-decoration: none;
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    background: #ff9800d4;
    border-radius: 5px;
    border: 1px solid #ffb84dd4;
    font-size: 1rem;

    span {
        pointer-events: none;
        white-space: nowrap;
        font-weight: inherit;
        letter-spacing: 0.03em;
        cursor: pointer;
        font-size: 1rem;
    }
}

.navlink-item .navlink  {
    color: rgba(255, 255, 255, 1) !important;
    margin-left: 0 !important;
    padding-top: 0 !important;
    padding-left: 15px !important;
    padding-bottom: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    margin-right: 0 !important;
    padding-right: 15px !important;
    text-decoration: none;
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    font-weight: inherit;
    letter-spacing: 0.03em;
    vertical-align: inherit;
    list-style-position: inside;
    font-size: 1rem;
    line-height: 150%;
    word-wrap: break-word;
    box-sizing: border-box;

    &:hover {
        outline: 0;
    }
}

.navlink-item .navlink .navlink-text {
    pointer-events: none;
    -webkit-tap-highlight-color: transparent;
    white-space: nowrap;
    box-sizing: border-box;
    color: rgba(255, 255, 255, 1) !important;
    font-weight: inherit;
    letter-spacing: 0.03em;
    cursor: pointer;
    text-align: -webkit-match-parent;
    list-style-position: inside;
    list-style: none;
    font-size: 1rem;
    line-height: 150%;
    font-family: "Titillium Web", "Titillium Web Fallback";
    -webkit-text-size-adjust: 100% !important;

    i {
        transition: transform .3s ease-out;
        text-decoration: none;
        display: inline-block;
        line-height: normal;
        font-size: 0.7rem;
        padding-left: 10px;
    }
}

.navlink-item .navlink .navlink-text::before {
    margin-bottom: .2em;
}

.navlink-item .navlink .navlink-text::after {
    margin-top: .2em;
}

.navlink-item .navlink .navlink-text::before {
    transform: translate3d(0, -8px, 0); /* Keep transform */
}

.navlink-item .navlink .navlink-text::after {
    transform: translate3d(0, 8px, 0); /* Keep transform */
}

.navlink-item .navlink .navlink-text::before,
.navlink-item .navlink .navlink-text::after {
    height: 2px; /* Set height for visibility */
    border-top-width: 2px;
    content: '';
    width: 100%;
    display: block;
    opacity: 0;
    pointer-events: none;
    transition: transform .2s ease-out, opacity .2s ease-out;
    background-color: currentColor; /* Ensure it inherits the text color */
}

.navlink-item .navlink:hover .navlink-text::before,
.navlink-item .navlink.active .navlink-text::before,
.navlink-item.focused .navlink .navlink-text::before {
    height: 2px; /* Maintain height on hover */
    transform: translate3d(0, 0, 0); /* Keep transform */
    opacity: 0; /* Make visible on hover */

}

.navlink-item .navlink:hover .navlink-text::after,
.navlink-item .navlink.active .navlink-text::after,
.navlink-item.focused .navlink .navlink-text::after {
    height: 2px; /* Maintain height on hover */
    transform: translate3d(0, 0, 0); /* Keep transform */
    opacity: 1; /* Make visible on hover */

}



.navlink-item .navlink-dropdown-menu {
    position: absolute;
    display: flex;
    justify-content: flex-start;
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 1rem;
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
    left: 15px !important;
    top: calc(100% - 0px - 0px) !important;
    box-shadow: rgba(0, 0, 0, 0.25) 0 3px 11px 0 !important;
    transform: translateY(-10px);
    flex-direction: column;
    z-index: -1;
    transition: transform .4s ease-out, opacity .1s ease-out;
    background-color: rgba(250, 249, 249, 0.94) !important;
}

.navlink-dropdown-menu,
.navlink-dropdown-menu-item-dropdown {
    opacity: 0;
    pointer-events: none;
    transform: translateY(-10px);
    visibility: hidden;
    transition: transform 0.3s ease, opacity 0.3s ease;
}

.navlink-dropdown-menu.visible,
.navlink-dropdown-menu-item-dropdown.visible {
    opacity: 1;
    pointer-events: all;
    transform: translateY(0);
    visibility: visible;
    z-index: 100;
}



.navlink-item:hover .navlink-dropdown-menu {
    opacity: 1;
    pointer-events: all;
    transform: translateY(0);
    z-index: 100;
    transition: transform .4s ease-out, opacity .1s ease-out;
    flex-direction: column;
    vertical-align: top;
    visibility: visible;
}




.navlink-item:hover .navlink-text i {
    transform: rotateX(180deg);
}

/* Base styling for nested dropdowns */
.navlink-item .navlink-dropdown-menu .navlink-dropdown-menu-item .navlink-dropdown-menu-item-dropdown {
    max-height: 70vh;
    overflow-y: auto;
    transform: translateX(-10%) translateY(0);
    left: 100%;
    position: absolute;
    top: 0;
    flex-direction: column;
    pointer-events: none;
    opacity: 0;
    visibility: hidden;
    transition: transform 0.4s ease-out, opacity 0.3s ease-out, visibility 0.3s ease-out;
    min-width: 100%;
    display: flex;
    justify-content: flex-start;
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 1rem;
    background-color: rgba(250, 249, 249, 0.94) !important;
    box-shadow: rgba(0, 0, 0, 0.25) 0 3px 11px 0 !important;
    color: rgba(72, 65, 65, 1) !important;
    border-radius: 1.5rem;
    border-top-left-radius: 0;
}

/* Show nested dropdown when .visible class is added */
.navlink-item .navlink-dropdown-menu .navlink-dropdown-menu-item .navlink-dropdown-menu-item-dropdown.visible {
    opacity: 1;
    pointer-events: all;
    transform: translateX(0) translateY(0);
    visibility: visible;
}


/* Link styling within dropdowns */
.navlink-dropdown-menu-item a,
.navlink-dropdown-menu-item-dropdown a {
    padding: 10px 20px;
    display: block;
    color: rgba(72, 65, 65, 1);
    text-decoration: none;
    white-space: nowrap;
}





.navbar-wrapper .navbar-container .navlinks-wrapper .navlinks .navlinks-list .navlink-item .navlink-dropdown-menu .navlink-dropdown-menu-item a.active {

     span {
        text-decoration: underline;
        color: #750b14 !important;
        text-underline-offset: 4px;
     }
}




.navbar-wrapper .navbar-container .navlinks-wrapper .navlinks .navlinks-list .navlink-item .navlink-dropdown-menu .navlink-dropdown-menu-item.focused {
    background-color: rgba(165, 165, 165, 0.534) !important;
}


.navbar-wrapper .navbar-container .navlinks-wrapper .navlinks .navlinks-list .navlink-item .navlink-dropdown-menu .navlink-dropdown-menu-item a:hover,
.navbar-wrapper .navbar-container .navlinks-wrapper .navlinks .navlinks-list .navlink-item .navlink-dropdown-menu .navlink-dropdown-menu-item a:focus {
    
    span {
        text-decoration: underline;
        text-underline-offset: 4px;
        color: rgba(72, 65, 65, 1);
    }

    .navlink-dropdown-menu-item-dropdown li a span {
        color: rgba(72, 65, 65, 1) !important;
    }

}



.navbar-wrapper .navbar-container .navlinks-wrapper .navlinks .navlinks-list .navlink-item .navlink-dropdown-menu .navlink-dropdown-menu-item:last-of-type {
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
}

.navbar-wrapper .navbar-container .navlinks-wrapper .navlinks .navlinks-list .navlink-item .navlink-dropdown-menu .navlink-dropdown-menu-item {
    display: flex;
    justify-content: flex-start;
    position: relative;
    font-weight: normal;
    flex-direction: column;
    vertical-align: top;
    text-align: -webkit-match-parent;
    unicode-bidi: isolate;
    pointer-events: all;
    list-style-position: inside;
    transition: 0.15s background-color ease-out, color 0.15s ease-out;

    i {
        transition: transform .2s ease-out;
        text-decoration: none;
        display: inline-block;
        line-height: normal;
        font-size: inherit;
        padding-left: 10px;
    }


    a {
        font-weight: bold !important;
        padding: 15px 30px 15px 15px;
        margin: 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        text-decoration: none;
        cursor: pointer;
        position: relative;
        letter-spacing: 0.03em;
        outline: none;
        vertical-align: inherit;
        box-sizing: border-box;
        transition: background-color 0.2s ease-out, color 0.2s ease-out;
    }

    a span {
        pointer-events: auto;
        white-space: nowrap;
        position: relative;
        color: rgba(72, 65, 65, 1) !important;
        font-weight: bold !important;
        transition: background-color 0.2s  ease-out, color 0.2s ease-out;
        
    }

    &::after {
        border-top-width: 0 !important;
        box-sizing: border-box;
        content: '' !important;
        width: 100% !important;
        height: 0;
        align-self: center;
        border: 0 solid currentColor;
    }

    &:hover {
        background-color: rgba(165, 165, 165, 0.534);

        i {
            transform: rotate(-90deg) translate(-5px, 3px) !important;
        }

        a span {
            text-underline-offset: 4px;


            .navlink-dropdown-menu-item a span {
                color: rgba(72, 65, 65, 1) !important;
            }
        }
        
    }

    

}



.skip-to-content-link {
    position: absolute;
    bottom: 20px; /* Position slightly below the top */
    left: 15px; /* Align to the left with a bit of padding */
    transform: translateY(-800%);
    background-color: #0078D4; /* Adjust color to your theme */
    color: #ffffff;
    padding: 8px 16px;
    border-radius: 5px;
    z-index: 1000;
    text-decoration: none;
    transition: transform 0.3s ease;
  }
  
  .skip-to-content-link:focus {
    transform: translateY(0); /* Slide down into view */
    outline-offset: 4px;
  }
  