.hero-content-wrapper {
    display: flex;
    max-width: 960px;
    position: relative;
    margin: 0 auto;
    width: 100%;
    font-family: "Titillium Web", "Titillium Web Fallback";
}

.hero-content-wrapper .hero-content {
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
    padding-top: 0 !important;
    padding-bottom: 20px !important;
    font-family: "Titillium Web", "Titillium Web Fallback";
    position: relative;
    width: 100%;
    display: inline-block;
    vertical-align: top;
    text-align: center !important;
    font-size: 16px;
}

.hero-content-wrapper .hero-content .hero-header {
    padding-top: 0 !important;
    padding-left: 0 !important;
    display: block !important;
    padding-bottom: 0 !important;
    float: none !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
    top: auto !important;
    max-width: 100% !important;
    left: 0 !important;
    width: 100% !important;
    margin-top: 0 !important;
    position: relative !important;
    margin-bottom: 8px !important;
    padding-right: 0 !important;
    min-width: 0 !important;
    height: auto !important;
    text-align: center;
    background-color: rgba(0, 0, 0, 0) !important;
    color: rgba(72, 65, 65, 1);
    font-family: "Titillium Web", "Titillium Web Fallback";

    h1 {
        direction: ltr;
        font-family: "Titillium Web", "Titillium Web Fallback";
        font-weight: 700;
        font-style: normal;
        text-decoration: none;
        text-align: center;
        margin-left: 0;
        margin-right: 0;
        margin: .45em 0;
        color: rgb(255, 251, 251);
        font-size: 65px !important;
    }
}

.hero-content-wrapper .hero-content .hero-description {
    padding-top: 2px !important;
    padding-left: 0 !important;
    padding-bottom: 2px !important;
    float: none !important;
    margin-right: auto !important;
    margin-left: auto !important;
    top: 0 !important;
    max-width: 768px !important;
    left: 0 !important;
    width: calc(100% - 96px) !important;
    margin-top: 8px !important;
    position: relative !important;
    margin-bottom: 8px !important;
    padding-right: 0 !important;
    min-width: 25px !important;
    height: auto !important;
    display: block !important;
    text-align: center;
    font-family: "Titillium Web", "Titillium Web Fallback";
    line-height: 150%;
    

    h2 {
        color: white;
        text-decoration: underline;
        font-size: 30px;
        line-height: 120%;
    }

    p {
        font-size: 24px !important;
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        unicode-bidi: isolate;
        font-family: "Titillium Web", "Titillium Web Fallback";
        font-weight: bold;
        color: rgb(255, 255, 255);
        text-align: center;
    }
}


@media (max-width: 400px) {
    .hero-content-wrapper .hero-content .hero-header h1 {
        font-size: 50px !important;
    }

}

@media (max-width: 300px) {
    .hero-content-wrapper .hero-content .hero-header h1 {
        font-size: 35px !important;
    } 

}

@media (max-width: 300px) {
    .hero-content-wrapper .hero-content .hero-header h1 {
        font-size: 35px !important;
    } 

    .hero-content-wrapper .hero-content .hero-description {
        margin-left: 1rem !important;
        margin-right: 1rem !important;
        width: calc(100% - 2rem) !important;
    } 

}

.hero-content-wrapper .hero-content .hero-cta {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 15px;
    padding-left: calc(15px + 1rem); /* Keeps text centered by adding equivalent padding */
    margin: auto !important;
    width: 50%;
    max-width: 320px;
    border: none;
    text-decoration: none;
    border-radius: 0;
    color: rgb(247, 247, 247);
    font-weight: 700;
    font-size: clamp(16px, 1.5vw, 18px);
    font-family: "Titillium Web", "Titillium Web Fallback";
    background-color: #9e1521;
    transition: background-color 0.2s ease, border-color 0.2s ease;
    cursor: pointer;
    margin-top: 2rem !important;
    text-align: center;

    &:focus {
        outline-offset: 8px;
    }
}

.hero-content-wrapper .hero-content .hero-cta span {
    display: inline-flex;
    align-items: center;
    color: rgb(247, 247, 247);
    font-weight: 700;
    transition: color 0.2s ease;
}

.hero-content-wrapper .hero-content .hero-cta .go-arrow {
    width: 1rem; /* Icon width */
    margin-right: -0.5rem;
    margin-left: 0.5rem;
    opacity: 0;
    transform: translateX(-0.5rem);
    transition: opacity 0.2s ease, transform 0.2s ease;
}

.hero-content-wrapper .hero-content .hero-cta:hover {
    background-color: #801019;
}

.hero-content-wrapper .hero-content .hero-cta:hover .go-arrow {
    opacity: 1;
    transform: translateX(0);
}
