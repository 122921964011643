.page-wrapper.first-responders {
    padding-bottom: 4rem;
}

.page-wrapper.first-responders .gallery-section .section-content-wrapper .content-text-wrapper {
    h2 {
        text-align: left;
    }

    .text-content p {
        text-align: left;
    }
}

@media (max-width: 785px) {
    .page-wrapper.page-wrapper.first-responders .section-content-wrapper .section-image-placeholder img {
        height: auto !important;
        width: auto !important;
        max-width: 100%;
    }
}