.page-wrapper.common-grounds {
    padding-bottom: 4rem;
}

.gallery-section.full-width {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #f5f5f5; /* Optional background */
    padding: 2rem 0; /* Optional padding */
}

.gallery-wrapper {
    max-width: 1200px; /* Adjust as needed for content width */
    width: 100% !important;
    margin: 0 auto;
}

.section-content-wrapper.mission-trips {
    flex-direction: column;

    h2 {
        text-align: left;
    }
}

.text-content-section.main-childcare .section-content-wrapper .content-text-wrapper {
    width: unset;
}

.content-text-wrapper {
    width: 100%;
}