.mission-trips-wrapper.admin .mission-trip:hover {
    transform: none;
    cursor: unset;

    h5 {
        text-decoration: none !important;
    }
}

.mission-trips-wrapper.admin {
    margin-top: 20px;
    background-color: #f8f8f8;
    padding: 1.5rem;
    border-radius: 8px;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}