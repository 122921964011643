.page-wrapper.childcare .text-content-section .section-content-wrapper .content-text-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.page-wrapper.childcare .text-content-section.main-childcare {
    padding-bottom: 2rem !important;
}

.page-wrapper.childcare .text-content-section .section-content-wrapper .info-icons-wrapper {
    gap: 1rem;
    margin-top: 1rem;
    

    .info-icon {
        font-size: 1.4rem;
        padding: 0.5rem;
    }

    a {
        color: #a70f1c;
        vertical-align: inherit;
        box-sizing: border-box;
        text-decoration: underline;
        font-size: 16px;
        margin: 0;
        transition: color 0.1s ease, font-weight 0.1s ease-out;

        &:hover {
            color: black;
            font-weight: bold;
        }
    }
}

.page-wrapper.childcare .section-content-wrapper {
    gap: 1rem
}


.page-wrapper.childcare .section-content-wrapper .section-image-placeholder.childcare {
    width: 190rem !important;
    justify-content: start !important;
    text-align: left;
    margin-top: 1rem;

    h2 {
        width: 100%;
        margin-bottom: 0 !important;
        margin-top: 0rem !important;
    }

    img {
        width: 100%;
        height: auto;
    }

    a {
        color: #a70f1c;
        vertical-align: inherit;
        box-sizing: border-box;
        text-decoration: underline;
        font-size: 16px;
        margin: 0;
        transition: color 0.1s ease, font-weight 0.1s ease-out;

        &:hover {
            color: black;
            font-weight: bold;
        }
    }
}

/* Mobile only */
@media (max-width: 1050px) {

    .page-wrapper.childcare .verified-wrapper {
        margin: 0 3% !important;
    }
  }


.page-wrapper.childcare .verified-wrapper {
    display: flex;
    max-width: 960px;
    margin: 0 auto;
    background-color: rgba(255, 255, 255, 0.89); /* Blackish semi-transparent background */
    border: 1px solid rgba(158, 158, 158, 0.5);
    padding: 20px;
    border-radius: 8px;
    align-items: center;
    border: 1px solid rgb(158, 158, 158);
    color: rgba(72, 65, 65, 1) !important;
}

.page-wrapper.childcare .checkmark-wrapper {
    position: relative; /* Necessary for positioning the checkmark on top */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
}

.page-wrapper.childcare .checkmark-wrapper .fa-certificate {
    font-size: 50px; /* Adjust size as needed */
    color: #a70f1c !important;
}

.page-wrapper.childcare .checkmark-wrapper .fa-check {
    position: absolute;
    font-size: 25px; /* Adjust size as needed */
    color: white; /* Color of the checkmark icon */
}

.page-wrapper.childcare .verified-text-wrapper {
    flex: 1;
}

