.page-wrapper.announcements-page {
    padding-bottom: 4rem;
}

.announcements-wrapper {
    width: 100%;
    gap: 1.5rem;
}

.announcements-wrapper .announcement {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 2rem;
    gap: 1.5rem;
    margin-top: 20px;
    background-color: #f8f8f8;
    padding: 1.5rem;
    border-radius: 8px;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.announcements-wrapper .announcement .announcement-date-wrapper {
    height: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 15%;
    margin: 0;
    
    i {
        font-size: 3.7rem;
        color: #750b14;
    }

    figcaption {
        font-size: 17px;
        font-weight: 600;
        color: rgba(72, 65, 65, 1);
    }
}


.announcements-wrapper .announcement .announcement-content-wrapper {
    width: 100%;
    text-align: left;
}