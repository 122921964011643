.content-columns-wrapper {
    display: flex;
    max-width: 960px;
    position: relative;
    margin: 0 auto;
    width: 100%;
    text-align: center;
    font-family: "Titillium Web", "Titillium Web Fallback";
    padding-bottom: 3rem;
}

/* Mobile only */
@media (max-width: 785px) {

    .content-columns-wrapper {
        flex-direction: column;
    }

    .content-columns-wrapper .content-column {
        width: 100% !important;
    }


}

.content-columns-wrapper .content-column {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
    padding-top: 10px;
    padding-bottom: 10px;

    font-family: "Titillium Web", "Titillium Web Fallback";
    display: inline-block;
    vertical-align: top;
    word-wrap: break-word;
    position: relative;
    width: 50%;
}

.content-columns-wrapper .content-column .content-header-wrapper {
    line-height: 150%;
    font-family: "Titillium Web", "Titillium Web Fallback";
    max-width: 100%;
    text-align: left;
    margin: 8px 0;
    padding: 2px 0;

    h2 {
        text-align: center;
        font-size: 30px;
        color: #a70f1c;
        direction: ltr;
        font-family: "Titillium Web", "Titillium Web Fallback";
        font-weight: 700;
        font-style: normal;
        text-decoration: none;
        margin-left: 0;
        margin-right: 0;
        margin-block-start: 0.83em;
        margin-block-end: 0.83em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        display: block;
        unicode-bidi: isolate;

    }
}

.content-columns-wrapper .content-column .newsfeed-wrapper {
    padding-left: 25px !important;
    padding-bottom: 0 !important;
    padding-right: 25px !important;
    margin-left: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 0 !important;
    margin-right: 0 !important;
    opacity: 1;
    max-width: 100%;
}

.content-columns-wrapper .content-column .newsfeed-wrapper .newsfeed-item-wrapper {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0;
    margin: 0;
    display: grid;
    width: 100%;

}
.content-columns-wrapper .content-column .newsfeed-wrapper .newsfeed-showmore-button {
    position: relative;
    display: inline-flex; /* Treats text and icon as a single inline-flex container */
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 15px;
    padding-left: calc(15px + 1rem); /* Keeps text centered by adding equivalent padding */
    margin: auto !important;
    width: 50%; /* Full width on smaller screens */
    max-width: 320px; /* Sets a max width for larger screens */
    border: none;
    border-radius: 0;
    text-decoration: none;
    color: rgb(247, 247, 247);
    font-weight: 700;
    font-size: clamp(16px, 1.5vw, 18px); /* Responsive font size */
    font-family: "Titillium Web", "Titillium Web Fallback";
    background-color: #9e1521;
    transition: background-color 0.2s ease, border-color 0.2s ease;
    cursor: pointer;
    margin-top: 2rem !important;
    text-align: center;

    &:focus {
        outline-offset: 5px;
    }
}

.content-columns-wrapper .content-column .newsfeed-wrapper .newsfeed-showmore-button span {
    display: inline-flex;
    align-items: center;
    color: rgb(247, 247, 247);
    font-weight: 700;
    transition: color 0.2s ease;
}

.content-columns-wrapper .content-column .newsfeed-wrapper .newsfeed-showmore-button .go-arrow {
    width: 1rem; /* Fixed width for consistency */
    opacity: 0;
    transform: translateX(-0.5rem); /* Initial position */
    transition: opacity 0.2s ease, transform 0.2s ease;
    margin-right: -0.5rem;
    margin-left: 0.5rem;
}

.content-columns-wrapper .content-column .newsfeed-wrapper .newsfeed-showmore-button:hover {
    background-color: #801019;
}

.content-columns-wrapper .content-column .newsfeed-wrapper .newsfeed-showmore-button:hover .go-arrow {
    opacity: 1;
    transform: translateX(0); /* Arrow moves in on hover */
}


.content-columns-wrapper .content-column .newsfeed-wrapper .newsfeed-item-wrapper .newsfeed-item {
    width: 100% !important;
    position: relative;
    overflow: visible;

}

.content-columns-wrapper .content-column .newsfeed-wrapper .newsfeed-item-wrapper .newsfeed-item .newsfeed-content-wrapper {
    border-style: solid !important;
    border-width: 0 !important;
    border-bottom-width: 2px !important;
    border-bottom-style: solid !important;
    border-bottom-color: rgba(229, 227, 223, 1) !important;
    animation-delay: 200.0ms;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    position: relative;
    max-width: 100%;
    vertical-align: top;
    text-align: left;
    line-height: 150%;
    direction: ltr;

}

.content-columns-wrapper .content-column .newsfeed-wrapper .newsfeed-item-wrapper .newsfeed-item .newsfeed-content-wrapper .newsfeed-content {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    height: 100%;
    text-align: left;
    line-height: 150%;

}

.content-columns-wrapper .content-column .newsfeed-wrapper .newsfeed-item-wrapper .newsfeed-item .newsfeed-content-wrapper .newsfeed-content .newsfeed-preview {
    flex: 1 1 auto;
    padding: 15px 0;
    display: block;
    width: 100%;
    text-align: left;
}


.content-columns-wrapper .content-column .newsfeed-wrapper .newsfeed-item-wrapper .newsfeed-item .newsfeed-content-wrapper .newsfeed-content .newsfeed-preview .newsfeed-title {
    text-align: left;

    h3 {
        text-align: left;
        margin: 0;
        padding: 0;
        line-height: normal;
        font-family: "Titillium Web", "Titillium Web Fallback";
        font-weight: normal;
        line-height: 1.5em;
        font-size: 20px !important;
        color: #750b14;
        text-align: left;
        font-weight: 700;
        text-decoration: none;
        display: block;
        word-wrap: break-word;


        i {
            color: #a70f1c;
        }
    }
}

.content-columns-wrapper .content-column .newsfeed-wrapper .newsfeed-item-wrapper .newsfeed-item .newsfeed-content-wrapper .newsfeed-content .newsfeed-preview .newsfeed-date {
    font-size: 16px !important;
    margin: 0.5rem 0;
    max-height: 1.2999999999999998em !important;
    color: rgba(72, 65, 65, 1) !important;
    padding: 0;
    overflow: hidden;
    line-height: 1.4em;
    word-break: break-word;
    font-weight: 700;
    
}

.content-columns-wrapper .content-column .newsfeed-wrapper .newsfeed-item-wrapper .newsfeed-item .newsfeed-content-wrapper .newsfeed-content .newsfeed-preview .announcement-content {
    p {
        margin: 0;
        color: rgba(72, 65, 65, 1) !important;
    }
}

.content-columns-wrapper .content-column .newsfeed-wrapper .newsfeed-item-wrapper .newsfeed-item .newsfeed-content-wrapper .newsfeed-content .newsfeed-cta {
    opacity: .8;
    text-align: left;
    color: rgba(72, 65, 65, 1);
    font-family: "Titillium Web", "Titillium Web Fallback";
    line-height: 150%;

    a {
        color: #a70f1c;
        text-decoration: none;
        display: block;
        outline: none;
        text-align: left;
        font-family: "Titillium Web", "Titillium Web Fallback";
        font-size: 18px;
    }
}

.content-columns-wrapper .content-column .start-times {
    transition: opacity 1s ease-in-out 0s;
    margin-left: auto !important;
    padding-top: 2px !important;
    padding-left: 0 !important;
    padding-bottom: 2px !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    margin-right: auto !important;
    padding-right: 0 !important;
    float: none !important;
    top: 0 !important;
    left: 0 !important;
    position: relative !important;
    height: auto !important;
    max-width: 100% !important;
    min-width: 0 !important;
    color: rgba(72, 65, 65, 1);
    font-family: "Titillium Web", "Titillium Web Fallback";
    
    p {
        text-align: middle;
        color: rgba(72, 65, 65, 1);
        font-family: "Titillium Web", "Titillium Web Fallback";
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        unicode-bidi: isolate;
        font-size: 20px !important;
        line-height: normal;
        font-weight: 500;
    }
}

.content-columns-wrapper .content-column .image-wrapper {
    width: 100% !important;
    height: 410px !important;
    margin-right: 0 !important;
    padding-top: 0 !important;
    padding-left: 0 !important;
    padding-bottom: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    margin-left: 0 !important;
    padding-right: 0 !important;
    line-height: 150%;
    clear: both;
    justify-content: start;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        max-width: 100%;
        vertical-align: top;
        overflow-clip-margin: content-box;
        overflow: clip;
        aspect-ratio: auto 462 / 410;

    }
}
.quick-links {
    color: rgba(72, 65, 65, 1);
    font-family: "Titillium Web", "Titillium Web Fallback";
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
}

.quick-links a {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 2rem;
    margin: auto !important;
    width: 50%;
    max-width: 320px;
    line-height: 1.2;
    cursor: pointer;
    background-image: none;
    text-decoration: none;
    border: none;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 15px;
    padding-left: calc(15px + 1rem); /* Keeps text centered by adding equivalent padding */
    color: rgb(247, 247, 247);
    font-weight: 700;
    font-size: clamp(16px, 1.5vw, 18px);
    font-family: "Titillium Web", "Titillium Web Fallback";
    background-color: #9e1521;
    transition: background-color 0.2s ease, border-color 0.2s ease;

    &:focus {
        outline-offset: 5px;
    }
}

.quick-links a .go-arrow {
    width: 1rem; /* Fixed width to keep spacing consistent */
    margin-right: -0.5rem;
    margin-left: 0.5rem;
    opacity: 0;
    transform: translateX(-0.5rem);
    transition: opacity 0.2s ease, transform 0.3s ease;
}

.quick-links a:hover {
    background-color: #801019;
}

.quick-links a:hover .go-arrow {
    opacity: 1;
    transform: translateX(0);
}



.quick-links a.facebook {
    background-color: #1877f2;


    i {
        margin-bottom: 0rem !important;
    }

    &:hover {
        background-color: #145bb8;
    }
}