.staff-section-wrapper {
    padding-top: 45px !important;
    padding-left: 80px !important;
    padding-right: 80px !important;
    margin-left: 0 !important;
    padding-bottom: 4rem !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    margin-right: 0 !important;
    width: auto !important;
    background-color: rgba(255, 255, 255, 1) !important;
    max-width: none;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    font-family: "Titillium Web", "Titillium Web Fallback";

    h2 {
        color: rgba(72, 65, 65, 1);
        font-size: 30px;
    }
}

.staff-section-wrapper .staff-row-wrapper {
    display: flex;
    position: relative;
    margin: 0 auto;
    width: 100% !important;
    max-width: 100% !important;
    justify-content: center;
    flex-wrap: wrap;
}

.staff-member-wrapper {
    font-family: "Titillium Web", "Titillium Web Fallback";
    position: relative;
    width: 32%;
    min-width: 215px;
    display: inline-block;
    vertical-align: top;
    background-image: none !important;
    background-color: rgba(0, 0, 0, 0) !important;
    background-position: 0 0 !important;
    background-size: auto !important;
    background-repeat: repeat !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-right: 14.25px !important;
    padding-left: 0 !important;
    margin: 0 !important;
    text-align: start !important;
    color: rgb(0, 0, 0) !important;
    box-sizing: border-box;
    padding: 1rem !important;
}

.staff-member {
    display: flex;
    max-width: 960px;
    position: relative;
    margin: 0 auto;
    width: 100%;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: center;
}

.staff-member .staff-member-image {
    position: relative;
    width: 100%;
    display: flex;
    vertical-align: top;
    background-image: none !important;
    background-color: rgba(0, 0, 0, 0) !important;
    background-position: 0 0 !important;
    background-size: auto !important;
    background-repeat: repeat !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-right: 4.53125px !important;
    padding-left: 0 !important;
    margin: 0 !important;
    text-align: start !important;
    color: rgb(0, 0, 0) !important;
    box-sizing: border-box;
    justify-content: center;

    img {
        height: 100%;
        width: 30%;
        vertical-align: top;
        line-height: 150%;
        border-radius: 1rem;
        min-width: 65px;
    }
}

.staff-member .staff-member-info-wrapper {
    font-family: "Titillium Web", "Titillium Web Fallback";
    position: relative;
    width: 100%;
    display: inline-block;
    vertical-align: top;
    justify-content: center;
}


.staff-member .staff-member-info-wrapper .staff-member-info {
    float: none !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    position: relative !important;
    height: auto !important;
    padding-top: 2px !important;
    padding-left: 0 !important;
    padding-bottom: 2px !important;
    max-width: 100% !important;
    padding-right: 0 !important;
    min-width: 0 !important;
    text-align: center !important;
    display: block !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    line-height: 150%;
}

.staff-member .staff-member-info-wrapper .staff-member-info .info-line {
    text-align: center !important;


    h3 {
        font-size: 20px;
        color: #434343;
        margin: 0;
    }

    a {
        color: #a70f1c;
        vertical-align: inherit;
        box-sizing: border-box;
        text-decoration: underline;
        font-size: 16px;
        margin: 0;
        transition: color 0.1s ease, font-weight 0.1s ease-out;
        word-wrap: break-word;

        &:hover {
            color: black;
            font-weight: bold;
        }
    }
}

.staff-member .staff-member-info-wrapper .staff-member-info .info-line:nth-child(2) {
    color: rgb(117, 117, 117) !important;
    font-size: 18px;
    font-weight: 600 !important;

    p {
        margin: 0 !important;
    }
    
}

.staff-member .staff-member-info-wrapper .staff-member-info .info-line:nth-child(4) {
    color: rgb(67, 67, 67) !important;
    font-size: 16px !important;
    
    p {
        margin-top: 0.5rem
    }
}