.navbar-backdrop {
    background-position: 50% 50% !important;
    background-repeat: repeat !important;
    background-size: auto !important;
    text-align: center;
    width: 100%;
    max-width: none;
    position: relative;
    display: block;
    unicode-bidi: isolate;
    padding: 5.7rem 0rem;
    background-color: #000000;
    background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20 20.5V18H0v-2h20v-2H0v-2h20v-2H0V8h20V6H0V4h20V2H0V0h22v20h2V0h2v20h2V0h2v20h2V0h2v20h2V0h2v20h2v2H20v-1.5zM0 20h2v20H0V20zm4 0h2v20H4V20zm4 0h2v20H8V20zm4 0h2v20h-2V20zm4 0h2v20h-2V20zm4 4h20v2H20v-2zm0 4h20v2H20v-2zm0 4h20v2H20v-2zm0 4h20v2H20v-2z' fill='%23363636' fill-opacity='0.4' fill-rule='evenodd'/%3E%3C/svg%3E");
}

@media (max-width: 785px) {
    .navbar-backdrop {
        padding: 2.75rem 0rem;
    }

}

.page-wrapper .years-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 9;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    background-color: rgba(26, 26, 26, 0.349); /* Adjust the opacity as needed */
}

.page-wrapper .years-wrapper .years {
    width: fit-content;
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    justify-content: center;
    align-items: center; /* Ensure content is vertically centered */
    padding-bottom: 0.3rem;
    position: relative;

    p {
        color: rgba(72, 65, 65, 1);
        font-family: "Titillium Web", "Titillium Web Fallback";
        font-weight: 700;
        margin: 0;
        z-index: 2; /* Make sure the text is above the overlay */
        font-size: 22px;
    }

    &::after {
        content: '';
        background-color: rgba(17, 17, 17, 0.55); /* Adjust the opacity as needed */
        z-index: 1; /* Make sure the overlay is below the text */
    }
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}


.hero {
    position: relative !important;
    margin-bottom: 0 !important;
    padding-right: 0 !important;
    min-width: 0 !important;
    text-align: center !important;
    height: auto !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-attachment: fixed !important;
    background-position: 50% 5%;
    padding-top: 32px !important;
    padding-left: 0 !important;
    padding-bottom: 65px !important;
    float: none !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
    top: auto !important;
    max-width: none !important;
    left: 0 !important;
    width: auto !important;
    margin-top: 0 !important;
    background-image: url('./../../assets/images/sanctuaryFull.webp') !important;

    &::before {
        background-color: rgb(0, 0, 0) !important;
        opacity: 0.63 !important;
        display: block !important;
        position: absolute !important;
        top: 0 !important;
        left: 0 !important;
        width: 100% !important;
        height: 100% !important;
        content: ' ' !important;
        border-radius: inherit;
        box-sizing: border-box;
    }

    &::after {
        clear: both;
        content: ' ';
        display: table;
        box-sizing: border-box;
    }
}


.announcements {
    padding-top: 65px !important;
    padding-bottom: 65px !important;
    margin-left: 0 !important;
    padding-left: 35px !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    margin-right: 0 !important;
    padding-right: 35px !important;
    width: auto !important;
    text-align: center;
    max-width: none;
    position: relative;
    background-color: white;   
}



.daily-readings {
    position: relative !important;
    margin-bottom: 0 !important;
    padding-right: 0 !important;
    min-width: 0 !important;
    text-align: center !important;
    height: auto !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-attachment: fixed !important;
    padding-top: 32px !important;
    padding-left: 0 !important;
    padding-bottom: 65px !important;
    float: none !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
    top: auto !important;
    max-width: none !important;
    left: 0 !important;
    width: auto !important;
    margin-top: 0 !important;
    background-image: url('./../../assets/images/bible2.webp') !important;

    &::before {
        background-color: rgb(0, 0, 0) !important;
        opacity: 0.60 !important;
        display: block !important;
        position: absolute !important;
        top: 0 !important;
        left: 0 !important;
        width: 100% !important;
        height: 100% !important;
        content: ' ' !important;
        border-radius: inherit;
        box-sizing: border-box;
    }

    &::after {
        clear: both;
        content: ' ';
        display: table;
        box-sizing: border-box;
    }
}

.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #9e1521;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    
    animation: none;
    visibility: hidden;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  


  
@media screen and (max-width: 785px) {
    .hero {
        background-size: fill !important;
        background-position: 50% !important;
        background-attachment: unset !important;
    }

    .daily-readings {
        background-size: fill !important;
        background-position: 50% !important;
        background-attachment: unset !important;
    }

    .text-content-section .section-content-wrapper .section-image-placeholder {
        order: -1;
    }
}