.admin-page {
    text-align: center;
    padding: 20px;
}

.button-group {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin: 20px 0;
}

.fa-spinner {
    font-size: 1rem;
}

.fa-check {
    font-size: 1rem;
}

.fa-times {
    font-size: 1rem;
}
.attachments-preview {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}

.attachment-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100px;
    margin: 0.5rem;
}

.attachment-preview {
    max-width: 100px;
    max-height: 100px;
    object-fit: cover;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 0.5rem;
}

.remove-attachment-wrapper {
    text-align: center;
}

.remove-attachment {
    background-color: #f5f5f5;
    border: none;
    color: #333;
    cursor: pointer;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    font-size: 0.9rem;
    display: inline-block;
    transition: background-color 0.3s;
}

.remove-attachment:hover {
    background-color: red;
    color: #fff;
}


/* Rotate animation */
@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(180deg);
    }
}

@keyframes sideJitter {
    0% {
        transform: translateX(0.15rem);
    }
    50% {
        transform: translateX(-0.15rem);
    }
    100% {
        transform: translateX(0);
    }
}


@keyframes jump {
    0% {
        transform: translateY(0.15rem);
    }
    50% {
        transform: translateY(-0.15rem);
    }
    100% {
        transform: translateY(0);
    }
}



.action-button {
    padding: 20px 30px;
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.527);
    border: 1px solid rgba(158, 158, 158, 0.5);
    background-color: #f8f8f8;
    border-radius: 0.8rem;
    cursor: pointer;
    transition: color 0.2s ease-in-out, border-color 0.15s ease-out, box-shadow 0.15s ease-out, background-color 0.2s ease-in-out;
    will-change: color, box-shadow, border, background-color, font-weight;

    i {
        will-change: transform, rotate, scale;
    }
}



.action-button:hover {
    border-color: rgba(99, 99, 99, 0.8);
    color: rgb(24, 24, 24);
    background-color: #fcfcfc;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.164);


    i.main {
        animation: rotate 0.3s ease-out;
    }

    i.alt {
        animation: sideJitter 0.3s ease-out;
    }

    i.add {
        animation: jump 0.3s ease-out;
    }
    
}

.feature-separator {
    height: 1px;
    border: none;
    background-color: rgba(158, 158, 158, 0.5);
}

.action-button.selected {
    border: 2px solid rgb(0, 0, 0);
    color: rgb(24, 24, 24);
    font-weight: bold;
    background-color: #ffffff;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.164);

        
    i.main {
        animation: rotate 0.3s ease-out;
    }

    i.alt {
        animation: sideJitter 0.3s ease-out;
    }
}


.form-container {
    margin-top: 20px;
    padding: 20px 30px;
    border: 1px solid rgba(158, 158, 158, 0.5);
    border-radius: 0.8rem;
    background-color: #f8f8f8;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    text-align: left;
    transition: box-shadow 0.2s ease-in-out, border-color 0.2s ease-in-out;
}

.form-container:hover {
    border-color: rgba(99, 99, 99, 0.8);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
}


.feature-form label {
    display: block;
    font-size: 1.15rem;
    font-weight: bold;
    color: rgba(24, 24, 24, 0.7);
    padding: 0.5rem;
}

.feature-form input,
.feature-form textarea,
.feature-form select {
    padding: 15px 12px;
    font-size: 1.1rem;
    color: rgba(24, 24, 24, 0.7);
    border: 1px solid rgba(158, 158, 158, 0.5);
    border-radius: 0.5rem;
    background-color: #ffffff;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    transition: border 0.15s ease-out, box-shadow 0.15s ease-out;
}

.feature-form input, .feature-form textarea {
    width: calc(100% - 26px);
}

.feature-form select {
    width: 100%;
}

.feature-form input:focus label {
    color: black !important;
}

.feature-form input:hover,
.feature-form textarea:hover,
.feature-form select:hover {
    border: 1px solid black;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.15);
}

.feature-form input:focus,
.feature-form textarea:focus,
.feature-form select:focus {
    border: 1.5px solid black;
}

.feature-form input:focus label {
    color: black;
}

.feature-form textarea {
    resize: vertical;
    min-height: 100px;
}

.feature-form .attachments-wrapper {
    padding: 0.5rem;

    label {
        padding: 0;
    }

    input {
        cursor: pointer;
    }
}

.feature-form .attachments-wrapper small {
    padding-left: 0.3rem;
}

.feature-form button[type="submit"] {
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: bold;
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.85);
    border: 1px solid rgba(158, 158, 158, 0.5);
    border-radius: 0.8rem;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    margin-top: 1.5rem;
}

.feature-form button[type="submit"]:disabled {
    background-color: rgba(0, 0, 0, 0.658) !important;
    box-shadow: none !important;
    border: 1px solid rgba(158, 158, 158, 0.5);
    cursor: not-allowed;
}

.feature-form button[type="submit"]:hover {
    background-color: rgba(0, 0, 0, 1);
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
}

