.page-title-subtitle {
    margin: 0;
    color: rgba(72, 65, 65, 1);
}

.page-wrapper.past-service .page-title h1 {
    margin: 0;
    padding-bottom: 0;
}

.page-wrapper.past-sessions,
.page-wrapper.past-service {
    padding-bottom: 4rem !important;
}

.page-wrapper.past-service .service-content-wrapper {
    width: fit-content;
    color: #750b14;
    max-width: 960px;
    margin: 0 auto;
    justify-content: center;
}

.video-container {
    width: 60vw; /* 60% of viewport width */
    max-width: 960px;
    margin: 0 auto;
    position: relative; /* Necessary for the absolute positioning of the iframe */
    overflow: hidden;
    padding-top: 56.25%; /* 16:9 aspect ratio (9/16 = 0.5625) */
}

.video-container iframe,
.video-container video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}


.page-wrapper.past-service .service-content-wrapper .service-video-wrapper {
    text-align: left;

    h3 {
        font-size: 24px;
        font-family: "Titillium Web", "Titillium Web Fallback";
        margin-bottom: 0;
    }
}

.page-wrapper .bulletin-wrapper {
    margin-top: 1rem;
    text-align: center;

    h3 {
        font-size: 24px;
        font-family: "Titillium Web", "Titillium Web Fallback";
        margin-bottom: 0.5rem;
        color: #750b14;
    }
}

.toggle-bulletin-button, .download-bulletin-button {
    border-radius: 0rem;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s;
    background-color: #1d1d1de7;
    color: white;
    border: none;
    font-family: "Titillium Web", "Titillium Web Fallback";
    text-decoration: none; /* Remove underline for download button */
    display: inline-flex;
    align-items: center;
    gap: 8px; /* Space between icon and text */
    margin-left: 1rem;
}

.toggle-bulletin-button:hover, .download-bulletin-button:hover {
    background-color: #414141e7;
}

.toggle-bulletin-button {
    border-radius: 0rem;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s;
    background-color: #1d1d1de7;
    color: white;
    border: none;
    font-family: "Titillium Web", "Titillium Web Fallback";
}

.toggle-bulletin-button:hover {
    background-color: #414141e7;
}

.pdf-drawer {
    width: 85%;
    max-height: 0px; /* Increase the height as needed to show more of the PDF */
    overflow: hidden;
    transition: max-height 0.5s ease-out, opacity 0.5s ease-out;
    opacity: 0;
    margin: 0 auto;
    margin-top: 1rem;
}

.pdf-drawer.visible {
    max-height: 1500px !important; /* Ensure the visible state has the same increased height */
    opacity: 1;
    transition: max-height 0.5s ease-in, opacity 0.5s ease-in;
}