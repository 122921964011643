.admin-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 960px;
    margin: 0 auto;
    width: 100%;
    text-align: center;
    flex-wrap: wrap;
    height: 66px;
    
  }

  .home-button i {
    margin-right: 0.4rem;
  }

  
  .home-button, .logout-button {
    background-color: #1d1d1de7;
    color: white;
    text-decoration: none;
    font-size: 1.2rem;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    font-family: "Titillium Web", "Titillium Web Fallback" !important;

    &:hover {
      background-color: #3f3f3fdc;
      color: rgb(194, 194, 194);
    }
  }

  .logout-button {
    background-color: #a70f1c;
    color: white;

    &:hover {
      background-color: #801019;
      color: rgb(194, 194, 194);
    }
  }

  
  .logout-button i {
    margin-left: 5px;
  }
  