.admin-christmas-child  {
    display: flex !important;
    width: 100% !important;
    flex-direction: row !important;
    gap: 2rem;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 4rem;
    background-color: #f8f8f8;
    padding: 1.5rem;
    border-radius: 8px;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.file-input-wrapper small {
    color: white;
}


/* Toggle switch container */
.toggle-switch {
    display: flex;
    align-items: center;
    gap: 10px;

    span {
        color: white;
    }
}

/* The switch itself */
.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;
}

/* Hide the default checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider (the visible toggle button) */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 24px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
}

/* Checkbox checked styling */
input:checked + .slider {
    background-color: #9e1521;
}

input:checked + .slider:before {
    transform: translateX(26px);
}


.actions-wrapper {
    margin-bottom: 1rem !important;
    display: flex;
    gap: 1rem;
    justify-content: center;
    max-width: 100%;
    flex-wrap: wrap;

    a {
        border-radius: 0rem;

        display: inline-block;
        line-height: 22px;
        cursor: pointer;
        box-shadow: none;
        background-image: none;
        border-style: solid;
        white-space: nowrap;
        transition: background-color .2s ease, border-color .2s ease;
        clear: both;
        position: relative;
        text-decoration: none;
        border: none;
        padding: 7px 12px;
        color:white;
        font-weight: 700;
        font-size: 18px;
        font-family: "Titillium Web", "Titillium Web Fallback";
        background-color: #1d1d1de7;
        margin-top: 0rem !important;
    
        &:hover {
            background-color: #3f3f3fdc;
        }

        &:focus {
            outline-offset: 5px;
        }

    }

    
    button {
        border-radius: 0rem;
        display: inline-block;
        line-height: 22px;
        cursor: pointer;
        box-shadow: none;
        background-image: none;
        border-style: solid;
        white-space: nowrap;
        transition: background-color .2s ease, border-color .2s ease;
        clear: both;
        position: relative;
        text-decoration: none;
        border: none;
        padding: 7px 12px;
        color:white;
        font-weight: 700;
        font-size: 18px;
        font-family: "Titillium Web", "Titillium Web Fallback";
        margin-top: 0rem !important;
        background-color: #9e1521;

        &:hover {
            background-color: #801019;
        }

        &:focus {
            outline-offset: 5px;
        }
    }
}


.admin-christmas-child .occ-highlight-wrapper {
    width: 30%;
}

.add-new-button.form {
    margin-top: 63px;

    a {
        background-color: #a70f1c;


        &:hover {
            background-color: #801019;
        }
    }
}

.add-new-button {
    margin-top: 63px;
    margin-bottom: 63px;


    a {
        border-radius: 0rem;
        margin: auto !important;
        display: inline-block;
        line-height: 22px;
        cursor: pointer;
        box-shadow: none;
        background-image: none;
        border-style: solid;
        white-space: nowrap;
        transition: background-color .2s ease, border-color .2s ease;
        clear: both;
        position: relative;
        text-decoration: none;
        border: none;
        padding: 12px 30px;
        color:white;
        font-weight: 700;
        font-size: 18px;
        font-family: "Titillium Web", "Titillium Web Fallback";
        background-color: #1d1d1de7;
        margin-top: 0rem !important;
    
        &:hover {
            background-color: #3f3f3fdc;
        }
    }
}

.add-edit-wrapper {
    font-family: "Titillium Web", "Titillium Web Fallback";
    margin-top: 1rem;
    margin-bottom: 4rem;

    h2 {
        color: #a70f1c;
        font-size: 1.9rem;
    }
}

.add-edit-form {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    h2 {
        width: fit-content;
    }
}

.add-edit-form form {
    padding: 3rem;
    background-color: #1d1d1de7;
    width: 40%;
    border-radius: 1rem;
    gap: 1.5rem;
    display: flex;
    flex-direction: column;
    font-family: "Titillium Web", "Titillium Web Fallback";
    border: 1px solid black;

    button[type=submit] {
        width: 100%;
        padding: 0.7rem;
        background-color: #9e1521;
        cursor: pointer;
        border: 1px solid black;
        color: white;
        font-size: 20px;
        font-weight: 700;


        &:hover {
            background-color: #801019;
        }
    }
}

.add-edit-form form .admin-input-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 0.5rem;


    input, textarea, select {
        width: 100%;
        padding-top: 0.7rem;
        padding-bottom: 0.7rem;
        background-color: white;
        border: 1px solid black;
        box-sizing: border-box;
        font-size: 18px;
        max-width: 100%;
    }
    

    input[type=file] {
        border: none !important;
    }


    label {
        font-size: 22px;
        font-weight: 600;
        color: white;
    }

    .checkbox-wrapper {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        margin-top: 1rem;
    }

    input[type="checkbox"] {
        width: 20px;
        height: 20px;
        accent-color: #9e1521;
        cursor: pointer;
    }

    .checkbox-label {
        font-size: 20px;
        font-weight: 600;
        color: white;
    }
}

.add-edit-form form .admin-input-wrapper .file-input-preview-wrapper{
    padding: 0.8rem;
    border-radius: 0rem;
    background-color: rgb(255, 255, 255);
    border: 1px solid black;
    box-sizing: border-box;
}