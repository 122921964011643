.page-wrapper.missiontrips section:nth-of-type(2) .section-image-placeholder {
    img {
        border-radius: 0;
        border: 3px solid rgb(134, 134, 134);
        box-shadow: rgba(0, 0, 0, 0.521) 0 1px 11px 0 !important;
        transition: transform 0.2s ease-out;
        will-change: transform;

        &:hover {
            transform: scale(1.05);
        }
    }
}


.page-wrapper .text-content-section .section-content-wrapper .section-image-placeholder {
    width: 100%; /* Ensure it takes the full width of its container */
    
    margin: 0 auto; /* Center it horizontally */
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 0.8rem;
}

.page-wrapper .slick-slider {
    width: 100%; /* Full width of the container */
    margin: 0 auto;
    max-width: 460px; /* Set a max-width to control the carousel's size */
}

.page-wrapper .slick-list {
    overflow: hidden;
}

.page-wrapper .slick-track {
    display: flex !important;
    align-items: center;
}

.page-wrapper .slick-slide {
    display: flex !important;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 100%; /* Ensure each slide takes up the full width */
    height: auto; /* Adjust height automatically based on the content */
    overflow: hidden; /* Hide any overflow content */
    box-sizing: border-box; /* Include padding and border in width/height calculations */
}

.page-wrapper .slick-slide img {
    border-radius: 5rem;
    width: 100%; /* Ensure the image takes full width of the slide */
    max-height: 18rem;
    object-fit: cover;
    transition: transform 0.2s ease-out;
    box-shadow: rgba(0, 0, 0, 0.514) 0 3px 8px !important;
    margin: 0.5rem
}

.page-wrapper.christmaschild .slick-slide img {
    max-height: 16rem;
}

.page-wrapper.christmaschild .slick-slide video {
    border-radius: 3rem !important;
    box-shadow: rgba(0, 0, 0, 0.514) 0 1px 6px !important;
}

.page-wrapper .slick-dots {
    display: flex !important;
    justify-content: center;
    gap: 5px;
    position: relative;
    margin-bottom: 1rem !important;
}

/* Add outline on button focus */
.page-wrapper .slick-dots li button:focus {
    outline: 2px solid black; /* Customize the color and thickness as desired */
    outline-offset: 4px; /* Offset for better visibility */
    border-radius: 50%; /* Rounded outline for circular dot buttons */
}

.carousel-item {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center !important;
    text-align: center;
    padding: 1rem 0;
}

.carousel-caption {
    margin: 0rem;
    font-size: 0.9rem;
    color: #333;
    padding-top: 1rem;
    padding-bottom: 1.5rem;
}

.page-wrapper .slick-dots li button:before {
    color: gray;
}

.page-wrapper .slick-dots li.slick-active button:before {
    color: black;
}

.mission-trips-wrapper {
    display: flex;
    gap: 2rem;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
    margin-top: 3rem;
}

a.mission-trip {
    text-decoration: none;
}

.mission-trips-wrapper .mission-trip {
    flex-basis: 30%;
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(158, 158, 158, 0.5);
    padding: 0rem;
    color: rgba(72, 65, 65, 1) !important;
    justify-content: flex-start !important;
    box-shadow: rgba(0, 0, 0, 0.205) 0 1px 11px 0 !important;
    height: 100% !important;
    min-width: 12rem;
    border-radius: 2rem;
    cursor: pointer;
    transition: transform 0.3s ease;
    will-change: transform;

    &:hover {
        transform: scale(1.02);

        h5 {
            text-decoration: underline;
        }
    }

    &:focus {
        outline-offset: 10px;
    }
}

.mission-trips-wrapper .mission-trip .mission-image-wrapper {
    img {
        width: 100%;
        max-height: 210px !important;
        border-top-right-radius: 2rem;
        border-top-left-radius: 2rem;
    }
}


.mission-trips-wrapper .mission-trip .mission-boxes-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h5 {
        color: #750b14;
        font-size: 28px;
        margin-top: 0.5rem;
        margin-bottom: 0;
    }

    p {
        margin-top: 0.2rem;
        margin-bottom: 0.5rem;
    }
}


.mission-trips-wrapper .mission-trip .mission-desc-wrapper {
    display: flex;
    justify-content: center !important;

    p {
        width: 70%;
        margin-top: 0;
        margin-bottom: 0.5rem;
        text-align: center;
    }
}

