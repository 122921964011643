.announcements-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: space-between;
}

.church-staff-list-wrapper {
    gap: 10px;
    margin-top: 20px;
    background-color: #f8f8f8;
    padding: 1.5rem;
    border-radius: 8px;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.staff-section-wrapper.admin-childcare {
    gap: 10px;
    margin-top: 20px;
    background-color: #f8f8f8 !important;
    padding: 1.5rem;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}



.announcement-item {
    flex: 1 1 calc(50% - 2rem); /* Half-width on larger screens */
    background-color: #f7f7f7;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border: 1px solid #ccc;
    color: #333;
}

.announcement-item h3 {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.announcement-item p {
    margin: 0.5rem 0;
    line-height: 1.6;
}



@media (max-width: 768px) {
    .announcement-item {
        flex: 1 1 100%; /* Full width on smaller screens */
    }
}
