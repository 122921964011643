.page-wrapper.ministries {
    padding-bottom: 4rem;
}

.ministries-cards-wrapper {
    width: 100%;
    justify-content: center;
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
}

.ministries-times-wrapper {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.ministries-cards-wrapper .ministries-card-wrapper.external {
    width: calc(27% - 1rem);
}

.ministries-cards-wrapper .ministries-card-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: calc(25% - 1rem);
    flex-wrap: wrap;
    min-width: 175px;
    text-decoration: none;
    transition: transform 0.3s ease;
    will-change: transform;
    
    .go-ministries, p span {
        transition: color 0.2s ease-out;
        will-change: transform, color;
    }

    h3 {
        color: rgba(72, 65, 65, 1);
        font-family: "Titillium Web", "Titillium Web Fallback";
        font-size: 22px;
        font-weight: 700;
        margin: 0;
    }
}

.ministries-cards-wrapper .ministries-card-wrapper:hover {
    transform: scale(1.005);

    .go-ministries, p span {
        color: #750b14 !important;
    }
    
}


.go-ministries {
    color: rgba(72, 65, 65, 1);
    padding: 0.3rem 1rem;
    font-family: "Titillium Web", "Titillium Web Fallback";
    font-size: larger;
    transition: all 0.2s ease-out;
    text-decoration: none;
    font-weight: 600;
    transition: color 0.2s ease-out;
    margin: 0;
    margin-bottom: 1rem;
}


/* Mobile only */
@media (max-width: 555px) {
    .page-wrapper .text-content-section .section-content-wrapper .daily-reading-wrapper {
        flex-direction: column;

        .reading-wrapper {
            width: 100% !important;
        }
    }
}

.ministries-cards-wrapper .ministries-card-wrapper .ministries-card {
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(158, 158, 158, 0.5);
    padding: 0rem;
    color: rgba(72, 65, 65, 1) !important;
    justify-content: flex-start !important;
    box-shadow: rgba(0, 0, 0, 0.205) 0 1px 11px 0 !important;
    background-color: white;
    overflow: hidden;
    transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
    will-change: transform, box-shadow;
    justify-content: center;
    max-width: 100%;
    border-radius: 1.5rem;
    

    &:hover {
        transform: scale(1.0);
        box-shadow: rgba(0, 0, 0, 0.37) 0 5px 18px 0 !important;
    }


    img {
        width: 100% !important;
        height: auto !important;
        max-height: 200px;
        overflow: hidden;
    }
    

    p {
        color: rgba(72, 65, 65, 1) !important;
        font-family: "Titillium Web", "Titillium Web Fallback";
        font-size: large;
        text-align: center;
        justify-content: center;
        display: flex;
        margin: 0;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        will-change: transform;
    }

    p span {
        width: 80%;
        margin: 0;
    }
}

.ministries-action-button.external {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    padding-right: 1rem !important;
    padding-left: 1rem !important; 
}

.ministries-action-button {
    border: 1px solid black;
    background-color: #9e1521;
    color: white;
    font-family: "Titillium Web", "Titillium Web Fallback";
    font-size: larger;
    transition: all 0.2s ease-out;
    text-decoration: none;
    font-weight: 700;
    width: 70%;
    margin: 0.5rem auto;
    margin-bottom: 0.8rem !important;
    transition: background-color .2s ease, border-color .2s ease;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    padding-right: 0.5rem;
    padding-left: 1.5rem; /* Keeps text centered by adding equivalent padding */
    color:rgb(247, 247, 247);
    font-weight: 700;
    font-size: 18px;
    font-family: "Titillium Web", "Titillium Web Fallback";
    will-change: transform;
    border-radius: 2rem;

    .go-arrow {
        width: 1rem; /* Fixed width to keep spacing consistent */
        margin-right: -0.5rem;
        margin-left: 0.5rem;
        opacity: 0;
        transform: translateX(-0.5rem);
        transition: opacity 0.2s ease, transform 0.3s ease;
    }

    &:hover {
        background-color: #801019;

        .go-arrow {
            opacity: 1;
            transform: translateX(0);
        }
    }

    &:focus {
        outline-offset: 6px;
    }
}

.ministries-cards-wrapper .ministries-card-wrapper .ministries-card .ministries-icon-wrapper {
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    

    i {
        font-size: 10rem;
        padding: 1rem;
    }
}