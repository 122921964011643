.footer {
    position: relative;
}

.footer .footer-wrapper {
    margin: 0;
    padding: 0;
    font-size: 1em;
    font-weight: normal;
    text-align: left;
    background: #fff;
}

.footer .footer-wrapper .footer-container {
    font-size: 1em;
    font-weight: normal;
    text-align: left;
    margin: 0;
    padding: 0;
}

.footer .footer-wrapper .footer-container .footer-content-wrapper {
    padding-top: 20px !important;
    padding-bottom: 60px !important;
    margin-left: 0 !important;
    padding-left: 35px !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    margin-right: 0 !important;
    padding-right: 35px !important;
    width: auto !important;
    position: relative;
    font-size: 1em;
    font-weight: normal;
    text-align: left;
    background-color: #000000;
    background-image: url("data:image/svg+xml,%3Csvg width='120' height='120' viewBox='0 0 120 120' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 0h2v20H9V0zm25.134.84l1.732 1-10 17.32-1.732-1 10-17.32zm-20 20l1.732 1-10 17.32-1.732-1 10-17.32zM58.16 4.134l1 1.732-17.32 10-1-1.732 17.32-10zm-40 40l1 1.732-17.32 10-1-1.732 17.32-10zM80 9v2H60V9h20zM20 69v2H0v-2h20zm79.32-55l-1 1.732-17.32-10L82 4l17.32 10zm-80 80l-1 1.732-17.32-10L2 84l17.32 10zm96.546-75.84l-1.732 1-10-17.32 1.732-1 10 17.32zm-100 100l-1.732 1-10-17.32 1.732-1 10 17.32zM38.16 24.134l1 1.732-17.32 10-1-1.732 17.32-10zM60 29v2H40v-2h20zm19.32 5l-1 1.732-17.32-10L62 24l17.32 10zm16.546 4.16l-1.732 1-10-17.32 1.732-1 10 17.32zM111 40h-2V20h2v20zm3.134.84l1.732 1-10 17.32-1.732-1 10-17.32zM40 49v2H20v-2h20zm19.32 5l-1 1.732-17.32-10L42 44l17.32 10zm16.546 4.16l-1.732 1-10-17.32 1.732-1 10 17.32zM91 60h-2V40h2v20zm3.134.84l1.732 1-10 17.32-1.732-1 10-17.32zm24.026 3.294l1 1.732-17.32 10-1-1.732 17.32-10zM39.32 74l-1 1.732-17.32-10L22 64l17.32 10zm16.546 4.16l-1.732 1-10-17.32 1.732-1 10 17.32zM71 80h-2V60h2v20zm3.134.84l1.732 1-10 17.32-1.732-1 10-17.32zm24.026 3.294l1 1.732-17.32 10-1-1.732 17.32-10zM120 89v2h-20v-2h20zm-84.134 9.16l-1.732 1-10-17.32 1.732-1 10 17.32zM51 100h-2V80h2v20zm3.134.84l1.732 1-10 17.32-1.732-1 10-17.32zm24.026 3.294l1 1.732-17.32 10-1-1.732 17.32-10zM100 109v2H80v-2h20zm19.32 5l-1 1.732-17.32-10 1-1.732 17.32 10zM31 120h-2v-20h2v20z' fill='%23363636' fill-opacity='0.4' fill-rule='evenodd'/%3E%3C/svg%3E");
    overflow: hidden;
}

.footer .footer-wrapper .footer-container .footer-content-wrapper .footer-content-columns-wrapper {
    display: flex;
    width: 100% !important;
    position: relative;
    margin: 0 auto;
    font-size: 1em;
    font-weight: normal;
    text-align: left;
    max-width: 960px !important;

}


.footer-captcha {
    margin-top: 0.7rem;
    width: 100%;
    display: flex;

}

@media (max-width: 580px) {
    .footer .footer-wrapper .footer-container .footer-bottom-wrapper .footer-bottom-content-wrapper {
        flex-direction: column !important;

        div {
            width: 100% !important;
        }
    }
}

@media (max-width: 785px) {
    .footer .footer-wrapper .footer-container .footer-content-wrapper {
        padding: 0% 3% !important;
    }
}


@media (max-width: 900px) {

    .footer-content-column .welcome-message-wrapper {
        max-width: 250px;
        margin: 0 auto;
    }

    .footer .footer-wrapper .footer-container .footer-content-wrapper .footer-content-columns-wrapper {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 1rem;
    }

    .footer .footer-wrapper .footer-container .footer-content-wrapper .footer-content-columns-wrapper .footer-content-column {
        width: 100% !important;

        div {
            justify-content: center !important;
            text-align: center;
            align-items: center;
        }

        h3 {
            text-align: center;
        }
    }


    .footer-social-icons-wrapper {
        justify-content: center !important;
        align-items: center !important;
        display: flex;
    }

    .footer-social-icons-wrapper .socials-wrapper {
        justify-content: center !important;
        align-items: center !important;
        display: flex;
        width: fit-content !important;
    }

}

.footer .footer-wrapper .footer-container .footer-content-wrapper .footer-content-columns-wrapper .footer-content-column {
    margin-left: 0 !important;
    padding-top: 0 !important;
    padding-left: 0 !important;
    padding-bottom: 10px !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    margin-right: 0 !important;
    padding-right: 0 !important;
    font-family: "Titillium Web", "Titillium Web Fallback";
    display: inline-block;
    vertical-align: top;
    font-size: 1em;
    font-weight: normal;
    text-align: left;
    position: relative;
    width: 25%;
}

.footer .footer-wrapper .footer-container .footer-content-wrapper .footer-content-columns-wrapper .footer-content-column:nth-child(4) {
    .footer-content-column-header {
        text-align: center !important;
    }

    
}

.footer .footer-wrapper .footer-container .footer-content-wrapper .footer-content-columns-wrapper .footer-content-column .footer-content-column-header {
    padding-top: 2px !important;
    padding-left: 0 !important;
    padding-bottom: 2px !important;
    float: none !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
    top: 0 !important;
    max-width: calc(100% - 0px) !important;
    left: 0 !important;
    width: 100% !important;
    margin-top: 15px !important;
    position: relative !important;
    margin-bottom: 8px !important;
    padding-right: 0 !important;
    min-width: 25px !important;
    height: auto !important;
    font-family: "Titillium Web", "Titillium Web Fallback";
    line-height: 1.5;
    text-align: left;

    h3 {
        font-size: 24px;
        font-family: "Titillium Web", "Titillium Web Fallback";
        color: white;
        margin: 0;
        word-wrap: break-word;
        
    }
}

.footer-content-column .footer-address-wrapper {
    margin-left: 0 !important;
    padding-left: 0 !important;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: -15px !important;
    margin-bottom: 0 !important;
    margin-right: 0 !important;
    padding-right: 0 !important;
    width: auto !important;
    position: relative;

}

.footer-content-column .footer-content-row-wrapper .footer-content-row {
    transition: none 0s ease 0s;
    text-align: left;
    display: block;
    margin: 8px 0;
    padding: 2px 0;
    font-weight: normal;

    p {
        font-size: 16px !important;
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        unicode-bidi: isolate;

        a {
            color: rgb(255, 255, 255);
            display: initial;
            vertical-align: inherit;
            transition: font-weight 0.1s ease-out;
            font-weight: normal;
    
            &:hover {
                font-weight: bold;
            }
        }
    }

    p span {
        font-weight: bold;
        color: rgb(255, 255, 255);
        text-align: left;
        font-family: "Titillium Web", "Titillium Web Fallback";
        margin: 0;
        word-wrap: break-word;
    }


}


.footer-content-column .pastor-image-wrapper {
    width: 101px !important;
    display: block !important;
    float: none !important;
    top: 0 !important;
    left: 0 !important;
    position: relative !important;
    padding-top: 0 !important;
    padding-left: 0 !important;
    padding-bottom: 0 !important;
    margin-right: auto !important;
    margin-left: auto !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-right: 0 !important;
    min-width: 0 !important;
    font-size: 1em;
    font-weight: normal;

    img {
        border-radius: 40% !important;
        z-index: 99 !important;
        position: relative !important;
        border-style: solid !important;
        border-width: 5px !important;
        border-color: rgba(209, 205, 202, 1) !important;
        height: auto;
        width: 100%;
        transition: all .2s ease-out !important;    
        max-width: 100%;
        display: inline-block;
        overflow-clip-margin: content-box;
        overflow: clip;
        margin-bottom: -1rem;
        margin-left: -0.3rem;
        will-change: transform;

        &:hover {
            transform: scale(1.06);
        }
    }
}

.footer-content-column .welcome-message-wrapper {
    transition: none 0s ease 0s;
    text-align: left;
    display: block;
    padding-top: 32px !important;
    padding-left: 25px !important;
    padding-bottom: 25px !important;
    margin-top: -23px !important;
    margin-bottom: 8px !important;
    padding-right: 25px !important;
    color: white;
    background-color: rgba(255, 255, 255, 0.1) !important;
    backdrop-filter: blur(3px);
    border-style: solid !important;
    border-width: 0 !important;
    border-radius: 1rem !important;
    font-family: "Titillium Web", "Titillium Web Fallback";

    p {
        text-align: center;
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        unicode-bidi: isolate;
        font-family: "Titillium Web", "Titillium Web Fallback";
        font-size: 18px !important;
    }

    p span {
        font-weight: 700;
        font-family: Quicksand, 'Quicksand Fallback';
        font-style: inherit;
        font-size: 18px !important;
        text-align: center;
    }
}

.footer-content-column .footer-contact-form-wrapper {
    float: none !important;
    top: 0 !important;
    left: 0 !important;
    position: relative !important;
    height: auto !important;
    padding-top: 10px !important;
    padding-left: 0 !important;
    padding-bottom: 0 !important;
    margin-left: 0 !important;
    max-width: 100% !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-right: 0 !important;
    min-width: 0 !important;
    text-align: center !important;
    display: block !important;
}

.footer-content-column .footer-contact-form-wrapper .footer-contact-form-container {
    text-align: center !important;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    display: block;
    unicode-bidi: isolate;
}

.footer-content-column .footer-contact-form-wrapper .footer-contact-form-container .footer-contact-form {
    max-width: 960px;
    position: relative;
    margin: 0 auto;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    box-sizing: border-box;
    display: block;
    unicode-bidi: isolate;
    text-align: center !important;
    font-size: 1em;
    font-weight: normal;
    color: white;

    &::before {
        box-sizing: border-box;
        content: ' ';
        display: table;
    }
}

.footer-content-column .footer-contact-form-wrapper .footer-contact-form-container .footer-contact-form .input-wrapper {
    position: relative;
    
    display: inline-block;
    vertical-align: top;
    clear: both;
    padding-left: 0.9375em;
    padding-right: 0.9375em;
    margin-top: 10px;
    text-align: left;
    unicode-bidi: isolate;
    line-height: 150%;
    word-wrap: break-word;

    label {
        font-family: "Titillium Web", "Titillium Web Fallback";
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        color: rgb(255, 255, 255) !important;
        cursor: default;
        box-sizing: border-box;
        text-align: left;
        font-size: 1em;
        font-weight: normal;
    }

    input {
        color: white;
        background-color: rgba(255, 255, 255, 0.1) !important;
        backdrop-filter: blur(3px);
        width: 100%;
        margin: 3px 0;
        height: 40px;
        padding: 0 10px;
        font-size: 16px;
        line-height: normal;
        font-family: inherit;
        box-sizing: border-box !important;
        overflow-clip-margin: 0px !important;
        overflow: clip !important;
        padding-block: 1px;
        padding-inline: 2px;
        text-transform: none;
        text-indent: 0px;
        text-shadow: none;
        display: inline-block;
        text-align: start;
        appearance: auto;
        cursor: text;
        text-rendering: auto;
        letter-spacing: normal;
        word-spacing: normal;
        word-wrap: break-word;
        border: none;

        border-bottom: 1px solid;
        border-bottom-color: rgb(189, 189, 189) !important;
        transition: border-color 0.2s ease, border-width 0.1s ease-in;

        &:hover {
            border-bottom-color: rgb(255, 255, 255) !important;
        }

        &:focus {
            border-width: 3px;
            border-bottom-color: rgb(255, 255, 255) !important;
        }

    }
}


.footer-content-column .footer-contact-form-wrapper .footer-contact-form-container .footer-contact-form .submit-wrapper {
    max-width: 100%;
    padding: 0;
    height: auto;
    white-space: nowrap;
    line-height: 22px;
    box-shadow: none;
    padding-left: 0.9375em;
    padding-right: 0.9375em;

    button {
        border-radius: 0rem;
        margin: auto !important;
        width: 100%; /* Full width on smaller screens */
        max-width: 320px; /* Sets a max width for larger screens */
        display: inline-block;
        line-height: 1.2; /* Adjusted line-height for better wrapping */
        cursor: pointer;
        box-shadow: none;
        background-image: none;
        border-style: solid;
        white-space: normal; /* Allows text to wrap */
        transition: background-color 0.2s ease, border-color 0.2s ease;
        clear: both;
        position: relative;
        text-decoration: none;
        border: none;
        padding: 12px 15px;
        color: rgb(247, 247, 247);
        font-weight: 700;
        font-size: clamp(16px, 1.5vw, 18px); /* Responsive font size */
        font-family: "Titillium Web", "Titillium Web Fallback";
        background-color: #9e1521;
        margin-top: 2rem !important;
        word-wrap: normal;

        &:hover{
            background-color: #801019;
        }
        
        &:focus {
            outline-offset: 8px;
        }

        &:disabled {
            color: #ffffffbd !important;
            background-color: #9e1520bd !important;
            cursor: not-allowed;
        }
    }
}

.footer-content-column .footer-contact-form-wrapper .footer-contact-form-container .footer-contact-form .input-wrapper.half {
    width: calc(50% - 1.875rem);
}

.footer-content-column .footer-contact-form-wrapper .footer-contact-form-container .footer-contact-form .input-wrapper.full {
    width: calc(100% - 1.875rem);

    textarea {
        color: white;
        background-color: rgba(255, 255, 255, 0.1) !important;
        backdrop-filter: blur(3px);
        height: 80px;
        min-width: 100%;
        max-width: 100%;
        margin: 3px 0;
        padding: 10px;
        min-height: 50px;
        font-size: 16px;
        box-sizing: border-box;
        overflow: auto;
        vertical-align: top;
        font-family: inherit;
        text-rendering: auto;
        letter-spacing: normal;
        word-spacing: normal;
        line-height: normal;
        text-transform: none;
        text-indent: 0px;
        text-shadow: none;
        display: inline-block;
        text-align: start;
        appearance: auto;
        cursor: text;
        overflow-wrap: break-word;
        

        border: none;

        border-bottom: 1px solid;
        border-bottom-color: rgb(189, 189, 189) !important;
        transition: border-color 0.2s ease, border-width 0.1s ease-in;

        &:hover {
            border-bottom-color: rgb(255, 255, 255) !important;
        }

        &:focus {
            border-width: 3px;
            border-bottom-color: rgb(255, 255, 255) !important;
        }
    }
}




.footer-social-icons-wrapper .socials-wrapper {
    padding: 0 !important;

    .social-icon {
        width: 37px;
        height: 37px;
        font-size: larger;
    }
}



.footer .footer-wrapper .footer-container .footer-bottom-wrapper {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
    max-width: none;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: auto !important;
    position: relative;
    font-size: 1em;
    font-weight: normal;
    background-color: #000000;
    

}

.footer .footer-wrapper .footer-container .footer-bottom-wrapper .footer-bottom-content-wrapper {
    display: flex;
    max-width: 960px;
    position: relative;
    margin: 0 auto;
    width: 100%;
    text-align: center;
    font-size: 1em;
    font-weight: normal;
    flex-direction: column;
}


.footer .footer-wrapper .footer-container .footer-bottom-wrapper .footer-bottom-content-wrapper .footer-credit-wrapper {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
    padding-top: 0px;
    padding-bottom: 1.5rem;
    padding-left: 0px;
    padding-right: 0px;
    position: relative;
    width: 100%;
    display: inline-block;
    vertical-align: top;
}

.footer .footer-wrapper .footer-container .footer-bottom-wrapper .footer-bottom-content-wrapper .footer-credit-wrapper .footer-credit {
    opacity: 1;
    transform: translate3d(0%, 0%, 0px);
    text-align: left;
    display: block !important;
    float: none !important;
    top: 0px !important;
    left: 0px !important;
    width: calc(100% - 0px) !important;
    position: relative !important;
    height: auto !important;
    padding-top: 2px !important;
    padding-left: 0px !important;
    padding-bottom: 2px !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
    max-width: 100% !important;
    margin-top: 24px !important;
    margin-bottom: 8px !important;
    padding-right: 0px !important;
    min-width: 25px !important;

    h3 {
        color: rgb(255, 255, 255);
        text-align: center !important;
        margin: 0;
        font-size: 14px !important;
        font-weight: normal;
        font-family: "Titillium Web", "Titillium Web Fallback";
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        
        p {
            font-size: 0.9rem;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: center;
            margin: 0;
        }

        span {
            align-items: center;
            display: flex;
            width: fit-content;
        }
    
        a {
            color: inherit;
            transition: color 0.15s ease;
            display: flex;
            justify-content: center;
            align-items: center;
            &:hover {
                color:#e4a906
            }
        }

    }

    
}


.footer .footer-wrapper .footer-container .footer-bottom-wrapper .footer-bottom-content-wrapper .footer-nav-wrapper {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
    position: relative;
    width: 100%;
    display: inline-block;
    vertical-align: top;
}


.footer .footer-wrapper .footer-container .footer-bottom-wrapper .footer-bottom-content-wrapper .footer-nav-wrapper .footer-nav-container {
    display: block !important;
    float: none !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    position: relative !important;
    height: auto !important;
    padding-top: 2px !important;
    padding-left: 0 !important;
    padding-bottom: 2px !important;
    max-width: 100% !important;
    padding-right: 0 !important;
    min-width: 0 !important;
    text-align: left;
    margin: 0;
    margin-top: 10px;
    font-size: 1em;
    font-weight: normal;
    box-sizing: border-box;
}

.footer .footer-wrapper .footer-container .footer-bottom-wrapper .footer-bottom-content-wrapper .footer-nav-wrapper .footer-nav-container .footer-navlinks {
    color: rgb(255, 255, 255);
    text-align: center;
    font-weight: normal;
    margin-left: 0;
    margin-right: 0;
    display: flex;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    unicode-bidi: isolate;
    box-sizing: border-box;
    justify-content: center;
    flex-wrap: wrap !important;

    a {
        color: rgb(255, 255, 255);
        display: initial;
        vertical-align: inherit;
        font-size: 14px !important;
        transition: font-weight 0.1s ease-out;
    }

    a.active {
        font-weight: bold;
    }
}