.bible-readings-wrapper {
    display: flex;
    max-width: 960px;
    position: relative;
    margin: 0 auto;
    width: 100%;
    text-align: center;
    align-items: center;
    font-family: "Titillium Web", "Titillium Web Fallback";
    gap: 1.5rem;
    justify-content: center;
    margin-bottom: 2rem;
    margin-top: 4.5rem;
    flex-wrap: wrap;

    h3 {
        color: white;
        font-size: 26px;
        margin: 0;
        margin-bottom: 1rem;
    }
}


.bible-reading-wrapper {
    width: fit-content !important;
    min-width: 1rem !important;
    word-wrap: break-word;
}


.bible-reading-wrapper:first-of-type .bible-reading {
    outline: 4px solid #750b14b2;
    outline-offset: 5px;
    border: 2px solid rgba(0, 0, 0, 0.616);
    background-color: rgba(255, 255, 255, 0.788);

    h4 {
        color: #750b14;
        text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.726);
    }
}

.bible-readings-wrapper .bible-reading-wrapper.today {
    margin-top: -2.6rem;
}

.bible-reading {
    background-color: rgba(255, 255, 255, 0.39);
    border-radius: 1rem;
    backdrop-filter: blur(4px);
    outline: 2px solid rgba(0, 0, 0, 0.616);
    padding: 1rem;


    h4 {
        font-size: 36px;
        margin-bottom: 0.5rem;
        color: white;
        text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.726);
        line-height: 100%;
        margin-top: 1.5rem;
    }

    h5 {
        font-size: 16px;
        margin-top: 0.5rem;
    }

}

.view-archive {
    color: white;
    font-size: larger;
    transition: color 0.2s ease-out, font-weight 0.2s ease-out;
    

    &:hover {
        color: white;
        font-weight: 700;
    }
}


@media (max-width: 555px) {
    .bible-reading h4 {
        font-size: 28px;
    }
}