.page-wrapper .text-content-section {
    padding-top: 60px;
    padding-bottom: 25px;
    margin-left: 0px !important;
    padding-left: 0px;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    margin-right: 0px !important;
    padding-right: 0px;
    width: auto !important;
    background-color: rgba(244, 244, 244, 1) !important;
    position: relative;
}

@media (max-width: 995px) {
    .page-wrapper .text-content-section .section-content-wrapper .pillars-wrapper {

        width: 100% !important;
    }

    .page-wrapper .text-content-section .section-content-wrapper .pillars-wrapper .pillar {
        width: 100% !important;
        margin-left: 0% !important;
        margin-right: 0% !important;
        min-width: none !important;
    }

    .page-wrapper .text-content-section .section-content-wrapper .pillars-wrapper .pillar .pillar-icon-wrapper {
        i {
            font-size: 4rem !important;
        }

    }

    .page-wrapper.beliefs .text-content-section .section-content-wrapper {
        margin: 0 !important;
    }

}


.page-wrapper.beliefs {
    padding-bottom: 4rem;
}

.page-wrapper.beliefs section:first-of-type {
    margin-bottom: 1.5rem !important;
}

.page-wrapper .section-content-wrapper {
    max-width: 960px;
    position: relative;
    margin: 0 auto;
    width: 100%;
    display: flex;
    box-sizing: border-box;


    h2 {
        margin-left: 0 !important;
        padding-top: 2px !important;
        padding-left: 0 !important;
        padding-bottom: 2px !important;
        margin-top: 0 !important;
        margin-bottom: 8px !important;
        margin-right: 0 !important;
        padding-right: 0 !important;
        color: #a70f1c !important;
        direction: ltr;
        font-family: "Titillium Web", "Titillium Web Fallback";
        font-weight: 700;
        font-style: normal;
        text-decoration: none;
        font-size: 30px;
        text-align: left;
    }

    p {
        margin: 0;
        color: rgba(72, 65, 65, 1);
        font-family: "Titillium Web", "Titillium Web Fallback";
    }

}

.page-wrapper .text-content-section .section-content-wrapper .content-text-wrapper {
    opacity: 1;
    transform: translate3d(0%, 0%, 0px);
    visibility: visible !important;
    transition-delay: unset !important;
    transition-duration: unset !important;
    animation-delay: unset !important;
    animation-duration: unset !important;
    transition-property: none !important;
    padding-right: 20px !important;
    margin-left: 0 !important;
    padding-top: 0 !important;
    padding-left: 0 !important;
    padding-bottom: 20px !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    margin-right: 0 !important;


}

.page-wrapper .text-content-section .section-content-wrapper .content-text-wrapper .text-content {
    float: none !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    position: relative !important;
    height: auto !important;
    padding-top: 2px !important;
    padding-left: 0 !important;
    padding-bottom: 2px !important;
    max-width: calc(100% - 0px) !important;
    padding-right: 0 !important;
    min-width: 25px !important;
    display: block !important;
    font-family: "Titillium Web", "Titillium Web Fallback";
    text-align: left;



    a {
        color: #a70f1c;
        transition: color 0.1s ease, font-weight 0.1s ease-out;

        &:hover {
            color: black;
            font-weight: bold;
        }
    }
}

.page-wrapper .text-content-section .section-content-wrapper .section-image-placeholder {
    opacity: 1;
    background-size: cover !important;
    position: relative;
    display: flex;
    align-items: center !important;
    

    img {
        border-radius: 5rem;
        height: 18rem;
        width: auto;
    }
}

.page-wrapper .text-content-section .section-content-wrapper .pillars-wrapper {
    width: 100% !important;
    display: flex;
    justify-content: center;
    gap: 3rem;
    margin-bottom: 3rem;
    flex-wrap: wrap;
}

.page-wrapper .text-content-section .section-content-wrapper .pillars-wrapper .pillar {
    width: 40%;
    background-color: white;
    border-radius: 2rem;
    padding: 2rem;
    box-shadow: rgba(0, 0, 0, 0.25) 0 3px 11px 0 !important;

    h2 {
        text-align: center;
    }
}

.page-wrapper .text-content-section .section-content-wrapper .pillars-wrapper .pillar .pillar-verse-wrapper {
    color: #a70f1c;
}


.page-wrapper .text-content-section .section-content-wrapper .pillars-wrapper .pillar-rows-wrapper {
    display: flex;
    flex-direction: column;
}

.page-wrapper .text-content-section .section-content-wrapper .pillars-wrapper .pillar .pillar-icon-wrapper {
    top: -3.5rem;
    left: 12rem;

    i {
        display: flex;
        padding: 0.5rem;
        height: fit-content;
        font-size: 5rem;
        background-color: #750b14;
        border-radius: 2rem;
        color: white;
        text-shadow: 2px 2px 7px rgba(0, 0, 0, 0.712);
        justify-content: center !important;
        box-shadow: rgba(0, 0, 0, 0.25) 0 3px 11px 0 !important;
    }
}