.image-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 10px;
    margin-top: 20px;
    background-color: #f8f8f8;
    padding: 1.5rem;
    border-radius: 8px;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.upload-form {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 20px;
    padding: 15px;
    background-color: #f8f8f8;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    
}

.upload-form input[type="file"] {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: #fff;
    cursor: pointer;
    font-size: 1em;
    font-family: "Titillium Web", "Titillium Web Fallback";
}

.upload-form button[type="submit"] {
    background-color: #a70f1c;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s ease;
    font-family: "Titillium Web", "Titillium Web Fallback";
    font-weight: 600;
}

.upload-form button[type="submit"]:hover {
    background-color: #801019;
}

.upload-status {
    text-align: center;
    font-size: 1em;
    color: black;
    margin-top: 10px;
}

.gallery-thumbnail {
    width: 100%;
    cursor: pointer;
}

.gallery-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.gallery-content {
    width: 90%;
    max-width: 800px;
    background: #181818bd;
    border-radius: 8px;
    padding: 20px;
    position: relative;
}

.admin-page-wrapper .image-item {
    overflow: unset !important;

}

.image-item {
    position: relative;
    display: flex;
    will-change: transform;
    overflow: hidden;


    &:hover {
        .gallery-delete-button {
            opacity: 1; /* Show button only on hover */
        }
    }
}

.gallery-image-wrapper {
    overflow: hidden;
}

.gallery-thumbnail {
    width: 100%;
    cursor: pointer;
    transition: transform 0.35s ease;
    border-radius: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    will-change: transform;
}

.image-item:hover .gallery-thumbnail {
    transform: scale(1.06); /* Slight zoom on hover */
}

.gallery-delete-button {
    position: absolute;
    top: -14px;
    right: -8px;
    color: white;
    border: none;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s ease;
    background-color: #a70f1c;


    &:hover {
        background-color: #801019;
    }
    

    i {
        pointer-events: none;
    }
}

