.staff-section-wrapper.fees {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
}

.offered-program-wrapper {
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(158, 158, 158, 0.5);
    padding: 2rem;
    border-radius: 1rem;
    color: rgba(72, 65, 65, 1) !important;
    max-width: 25%;
    transition: border-color 0.2s ease;
    min-width: 4.5rem;
    background-color: rgba(244, 244, 244, 1) !important;

    border-color: #750b14;
    word-wrap: break-word;

    h3 {
        color: #a70f1c;
        font-size: 42px;
        margin: 0;
    }

    p {
        color: black;
    }

}


.program-call-button {
    padding: 0.5rem;
    background-color: #a70f1c;
    border-radius: 0.4rem;
    margin: 0;
    transition: background-color 0.2s ease-out, visibility 0.4s ease, opacity 0.2s ease-out;
    color: white;
    text-decoration: none;
    visibility: hidden;
    opacity: 0;

    &:hover {
        background-color: #750b14;
    }
}


.page-wrapper.childcare .staff-section-wrapper .staff-row-wrapper {
    gap: 3rem !important
}

.page-wrapper.childcare .staff-section-wrapper {
    gap: 2rem !important
}


.section-row-separator {
    width: 100% !important;
    margin-left: 0 !important;
    padding-top: 0 !important;
    padding-left: 0 !important;
    padding-bottom: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    margin-right: 0 !important;
    padding-right: 0 !important;
    max-width: 100%;
    vertical-align: top;
    box-sizing: border-box;

    hr {
        background: none !important;
        height: 0 !important;
        background-color: transparent !important;
        background-size: initial !important;
        min-height: 0 !important;
        border-color: currentColor !important;
        border-width: 1px !important;
        border-top-style: dotted !important;
        color: rgb(99, 97, 96) !important;
        display: block;
        margin: 10px 0;
        border: none;
        margin-block-start: 0.5em;
        margin-block-end: 0.5em;
        margin-inline-start: auto;
        margin-inline-end: auto;
        unicode-bidi: isolate;
        overflow: hidden;
        text-align: center;
    }
}


.page-wrapper.childcare .staff-section-wrapper {
    h2 {
        color: #a70f1c
    }
}


@media (max-width: 785px) {
    .offered-program-wrapper {
        max-width: 75%;
        padding: 1rem;
        h3 {
            font-size: 36px;
        }

    }
}