.staff-name-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 14rem;
    border-bottom: 1px solid rgb(150, 150, 150);
}

.staff-actions {
    display: flex;
}


.edit-button,
.delete-button {
    background: none;
    border: none;
    color: #000000;
    cursor: pointer;
    text-decoration: none;
    font-size: 1.1rem;
    transition: background-color 0.2s ease-out, color 0.2s ease-out;
    font-family: "Titillium Web", "Titillium Web Fallback";
}

.edit-button:hover {
    background-color: rgb(219, 219, 219);
}

.delete-button {
    background-color: #a70f1c;
    color: white;
    padding-top: 0.2rem;

    &:hover {
        background-color: #801019;
        color: rgb(194, 194, 194);
    }
    
}

.admin-childcare .director-wrapper {
    display: flex !important;
    justify-content: center;
}

.staff-section-wrapper.admin-childcare .info-line.admin {
    font-size: larger !important;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}