.childcare-forms {
    background-color: white;
    padding: 3rem 0;
    

    h3 {
        margin-top: 0;
    }
}

.childcare-form-buttons-wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 65%;
    justify-content: center;
    justify-self: center;
    gap: 1.5rem;
    

    a {
        text-decoration: none !important;

        &:focus {
            outline-offset: 5px;
        }
    }
}

.external-pdf {
    border-radius: 2rem;
}

.childcare-form-buttons-wrapper .open-pdf-button {
    border-radius: 0;
    margin: 0 !important;
    display: inline-block;
    align-items: center;
    justify-content: center;
    line-height: 1.2; /* Adjusted for better text wrapping */
    cursor: pointer;
    box-shadow: none;
    background-image: none;
    border-style: solid;
    white-space: normal; /* Allows text to wrap */
    transition: background-color 0.2s ease, border-color 0.2s ease;
    clear: both;
    position: relative;
    text-decoration: none;
    border: none;
    padding: 12px 5%; /* Responsive padding */
    color: white;
    font-weight: 700;
    font-size: clamp(16px, 1.5vw, 18px); /* Responsive font size */
    font-family: "Titillium Web", "Titillium Web Fallback";
    background-color: #1d1d1de7;
    margin-top: 0rem !important;
    border-radius: 0rem;
    

    &:hover {
        background-color: #3f3f3fdc;
    }
}

.childcare-form-buttons-wrapper .open-pdf-button.external {
    background-color: #9e1521;
    border-radius: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    

    &:hover {
        background-color: #801019;
    }
}