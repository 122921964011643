/* Consolidated CSS */

/* Image grid styling */
.image-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 10px;
    margin-top: 20px;
    background-color: #f8f8f8;
    padding: 1.5rem;
    border-radius: 8px;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Thumbnail styling for gallery images */
.gallery-thumbnail {
    width: 100%;
    cursor: pointer;
    border-radius: 4px;
    object-fit: cover;
    transition: transform 0.35s ease;
}

/* Hover effect for gallery thumbnails */
.image-item:hover .gallery-thumbnail {
    transform: scale(1.07);
}

/* Modal overlay styling */
.gallery-modal {
    position: fixed;
    inset: 0;
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(5px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999; /* Ensure this is above all other elements */
    overflow: hidden;
}

/* Modal content box */
.gallery-content {
    width: 80%;
    max-width: 800px;
    background: #fff; 
    border-radius: 10px;
    padding: 10px;
}

/* Delete button styling */
.common-grounds-delete-button {
    position: absolute;
    top: -14px;
    right: -8px;
    color: white;
    border: none;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    cursor: pointer;
    opacity: 0;
    background-color: #a70f1c;
    transition: opacity 0.3s ease, transform 0.35s ease;
}

/* Show delete button only on hover */
.image-item:hover .common-grounds-delete-button {
    opacity: 1;
    transform: scale(1.07);
}

.common-grounds-delete-button:hover {
    background-color: #801019;
}

.gallery-wrapper {
    width: 100% !important;
}
