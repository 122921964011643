.page-wrapper .membership-page-content-section {
    padding-top: 30px !important;
    padding-bottom: 80px !important;
    margin-left: 0px !important;
    padding-left: 35px !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    margin-right: 0px !important;
    padding-right: 35px !important;
    width: auto !important;
    position: relative;
}


.page-wrapper.baptism {
    padding-bottom: 4rem;
}

/* Mobile only */
@media (max-width: 785px) {
    .page-wrapper .membership-page-content-section .section-content-wrapper {
        flex-wrap: wrap;
    }

    .page-wrapper.baptism .section-content-wrapper .section-image-placeholder img {
        height: auto !important;
        width: auto !important;
        max-width: 100%;
    }

    .page-wrapper .text-content-section .section-content-wrapper {
        flex-wrap: wrap;
    }

    .page-wrapper .membership-page-content-section {
        padding: 5% !important;
    }

    .page-wrapper .text-content-section .section-content-wrapper .section-image-placeholder img{
        width: auto !important; /* Ensure it takes the full width of its container */
        height: auto;
        max-width: 100% !important;
    }
}

.page-wrapper .membership-page-content-section .section-content-wrapper {
    display: flex;
    max-width: 960px;
    position: relative;
    margin: 0 auto;
    width: 100%;
    box-sizing: border-box;
    font-family: "Titillium Web", "Titillium Web Fallback";
}

.page-wrapper .membership-page-content-section .section-content-wrapper .section-text-column {
    flex: 1 1 60%; /* Adjusts to take 60% of the width */
    padding-right: 20px !important;

    h2 {
        margin-left: 0 !important;
        padding-top: 2px !important;
        padding-left: 0 !important;
        padding-bottom: 2px !important;
        margin-top: 0 !important;
        margin-bottom: 8px !important;
        margin-right: 0 !important;
        padding-right: 0 !important;
        font-size: 30px;
        color: #a70f1c;
        text-align: left;
    }
}



.page-wrapper .membership-page-content-section .section-content-wrapper .section-text-column .text-block {
    margin-top: 0 !important;
    float: none !important;
    top: 0 !important;
    left: 0 !important;
    
    position: relative !important;
    height: auto !important;
    padding-top: 0 !important;
    padding-left: 0 !important;
    padding-bottom: 2px !important;

    margin-left: 0 !important;
    max-width: calc(100% - 0px) !important;
    margin-bottom: 0 !important;
    padding-right: 0 !important;
    min-width: 25px !important;
    display: block !important;

    p {
        margin-top: 0;
        margin-bottom: 0;
        color: rgba(72, 65, 65, 1);
        font-family: "Titillium Web", "Titillium Web Fallback";
        text-align: left;
        font-size: 16px;
        font-weight: 700;
    }
}

.page-wrapper .membership-page-content-section .section-content-wrapper .section-text-column .text-content-wrapper {
    float: none !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    position: relative !important;
    height: auto !important;
    padding-top: 2px !important;
    padding-left: 0 !important;
    padding-bottom: 2px !important;
    max-width: calc(100% - 0px) !important;
    padding-right: 0 !important;
    min-width: 25px !important;
    display: block !important;
    color: rgba(72, 65, 65, 1);
    font-family: "Titillium Web", "Titillium Web Fallback";
    text-align: left;
    margin: 8px 0;

    p {
        margin-top: 0;
        margin-bottom: 0;
        font-weight: normal;
        display: initial;
        font-size: 16px;
    }
}

.section-image-column {
    flex: 1 1 40%; /* Adjusts to take 40% of the width */
    display: flex;
    align-items: center; /* Centers the image vertically */
    justify-content: center; /* Centers the image horizontally */
    overflow-x: hidden;

    img {
        width: 100%;
        height: 100% !important;
        max-height: 100%; /* Ensures it does not exceed container height */
        object-fit: cover; /* Ensures it covers the container proportionally */
        border-radius: 8px; /* Optional: add rounded corners */
    }
}