.admin-dashboard-wrapper .admin-dashboard {
    display: flex;
    position: relative;
    width: 100%;
    text-align: center;
    font-family: "Titillium Web", "Titillium Web Fallback";
    justify-content: center;

    background-color: #f8f8f8;

    border-radius: 8px;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 2rem;
    flex-wrap: wrap;
    box-sizing: border-box;
}

.admin-dashboard-wrapper .admin-dashboard .dashboard-column {
    width: 30%;
    display: flex;
    flex-direction: column;
    margin: 0.5rem;
    flex-wrap: nowrap;
    min-width: 15rem;
    
    h3 {
        font-family: "Titillium Web", "Titillium Web Fallback";
        color: rgba(72, 65, 65, 1);
        text-decoration: underline;
        text-underline-offset: 3px;
    }
}

.upload-form button:disabled {
    background-color: #a70f1c71 !important;
    color: #fff !important;
    cursor: not-allowed !important;
}

.dashboard-button {
    border-radius: 0rem;
    margin: auto !important;
    display: inline-block;
    line-height: 22px;
    cursor: pointer;
    box-shadow: none;
    background-image: none;
    border-style: solid;
    white-space: nowrap;
    transition: background-color .2s ease, border-color .2s ease;
    clear: both;
    position: relative;
    text-decoration: none;
    border: none;
    padding: 12px 30px;
    color:white;
    font-weight: 700;
    font-size: 18px;
    font-family: "Titillium Web", "Titillium Web Fallback";
    background-color: #1d1d1de7;
    margin-bottom: 2rem !important;
    margin-top: 0rem !important;

    &:hover {
        background-color: #3f3f3fdc;
        text-decoration: underline;
        text-underline-offset: 1.5px;
    }
}

.dashboard-button.red {
    color: white;
    background-color: #9e1521;

    &:hover {
        background-color: #801019;
    }
}

.admin-welcome {
    font-family: "Titillium Web", "Titillium Web Fallback";
    color: rgba(72, 65, 65, 1);
}