.daily-readings-wrapper.admin {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    background-color: #f8f8f8;
    padding: 1.5rem;
    border-radius: 8px;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);


}



.bible-reading.admin h3 {
    color: rgba(72, 65, 65, 1) !important;
    text-shadow: none;
}

.bible-reading.admin {
    background-color: rgb(255, 255, 255);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.356);
    outline: none !important;
    border-radius: 1rem;
    backdrop-filter: blur(4px);
    border: 2px solid rgba(0, 0, 0, 0.616);
    padding: 1rem;

    h3 {
        color: rgba(72, 65, 65, 1) !important;
    }

    h5 {
        color: #750b14;
        font-size: larger;
    }
}
