



.admin-table thead th.actions-column {
    width: 120px;
}

.admin-table tbody tr {
    background-color: inherit;
    color: rgba(72, 65, 65, 1);
    line-height: 2.82em;
    position: relative;
    transition: 0.2s ease;
}

.admin-table tbody td {
    padding: 10px;
    font-weight: 600;
}

.admin-table tbody tr:hover {
    background-color: rgb(236, 236, 236);
    transition: 0.2s ease;
}

.actions {
    text-align: right;
    padding-right: 10px;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.admin-table tbody tr:hover .actions {
    opacity: 1;
}

.admin-table tbody tr:hover td {
    color: black;
}

.admin-table tbody tr .actions .view-details-btn {
    color:white;
    font-weight: 700;
    font-size: 13px;
    font-family: "Titillium Web", "Titillium Web Fallback";
    background-color: #1d1d1de7;
    cursor: pointer;
    transition: background-color .2s ease;
    border: none;
    padding-top: 7px;
    padding-bottom: 7px;

    &:hover {
        background-color: #414141e7;
    }
}

/* Table layout and structure */
.admin-table {
    margin: 0 auto;
    margin-bottom: 3rem;
    width: 100%; /* Ensure table takes full width of container */
    border-collapse: collapse;
    table-layout: fixed; /* Prevent table from expanding */
    background-color: #f8f8f8;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 2rem;
}

.admin-table thead {
    color: white;
    background-color: #9e1521;
    font-size: larger;
}





.details-row {
    background-color: #f8f8f8;
}

.message-content-wrapper {
    max-height: 0;
    word-wrap: break-word;
    transition: max-height 0.5s ease, padding 0.5s ease;
    padding: 0;
    opacity: 0;
}

.message-content-wrapper .message-header {
    text-decoration: underline;
    font-size: 19px;
    font-weight: 700;
}

.details-row.expanded {
    border-top: 1px solid rgba(72, 65, 65, 0.25);
    border-bottom: 1px solid rgba(72, 65, 65, 0.25);
    background-color: rgb(236, 236, 236);
    color: black;
}

.details-row .delete-button {
    font-weight: 600;
    padding: 4px 5px;
}

.details-row.expanded .message-content-wrapper {
    max-height: 500px; /* Arbitrary high value */
    padding: 10px; /* Adds padding when expanded */
    opacity: 1;
    transition: max-height 0.5s ease, padding 0.5s ease, opacity 0.5s ease;
}


.message-content-wrapper p {
    margin: 0;
}

