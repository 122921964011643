.loginWrapper {
    display: flex;
    justify-content: center; /* Centers horizontally */
    align-items: center; /* Centers vertically */
    padding-top: 8rem;
    padding-bottom: 8rem;
}


.login-container {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    padding: 3rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px; /* Adjust as needed */
    justify-content: center;
}

.unique-login-class form {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    gap: 1.5rem;
}

.unique-login-class input[type="text"],
.unique-login-class input[type="password"] {
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
    margin-bottom: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 80%;
    line-height: 3rem;
    background-color: #f5f5f5;
    transition: 0.3s ease;
    outline: 0.04rem solid transparent;
}

.unique-login-class input[type="text"]:focus,
.unique-login-class input[type="password"]:focus {
    outline: 0.04rem solid rgba(72, 65, 65, 1);
    transition: 1s ease;
}

.unique-login-class button {
    padding: 1rem 1.5rem;
    border: 1px solid rgba(72, 65, 65, 1);
    width: 80%;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
    margin-top: 1rem;
    font-size: larger;
    font-weight: bold;
    transition: 0.2s ease;
    background-color: #9e1521;
    color: white;


    &:hover {
        background-color: #801019;
    }
}


.unique-login-class .errmsg {
    margin-top: 2rem;
    background-color: #f8d7da;
    color: #721c24;
    padding: 0.75rem;
    border-radius: 4px;
    font-size: 14px;
    text-align: center;
    width: 88%;
    align-self: center;
}

.unique-login-class .input-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.unique-login-class label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
    text-align: center;
    width: auto;
}
