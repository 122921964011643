.contactbar-wrapper {
    transition: height .2s ease, background-color .2s ease, padding .2s ease, width .2s ease, margin .2s ease, top .2s ease, transform .2s ease;
    padding-right: 0 !important;
    padding-left: 0 !important;
    margin-top: -14px !important;
    float: none !important;
    top: 0 !important;
    left: 0 !important;
    width: auto !important;
    position: relative !important;
    z-index: 10;
    height: auto !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
    max-width: 100% !important;
    margin-bottom: 0 !important;
    min-width: 0 !important;
    text-align: center !important;
}

.contactbar-wrapper .contactbar-container {
    display: flex;
    width: 100% !important;
    max-width: 100% !important;
    position: relative;
    margin: 0 auto;
}

.contactbar-wrapper .contactbar-container .contactbar-column-1 {
    padding-left: 0 !important;
    margin-left: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    margin-right: 0 !important;
    padding-right: 0 !important;
    color: rgba(72, 65, 65, 1);
    font-family: "Titillium Web", "Titillium Web Fallback";
    position: relative;
    width: 25%;
    display: inline-block;
    vertical-align: top;
}

.contactbar-wrapper .contactbar-container .contactbar-column-1 .socials-placeholder {
    line-height: 150%;
    max-width: 100%;
    text-align: center !important;
    font-size: 16px;
}


.socials-wrapper {
    transition: height .2s ease, background-color .2s ease, padding .2s ease, width .2s ease, margin .2s ease, top .2s ease, transform .2s ease;
    float: none !important;
    top: 0 !important;
    left: 0 !important;
    width: 198px !important;
    position: relative !important;
    height: auto !important;
    padding-top: 25px !important;
    padding-left: 45px !important;
    padding-bottom: 15px !important;
    margin-right: 8.5px !important;
    margin-left: 0 !important;
    max-width: 100% !important;
    margin-top: 7px !important;
    margin-bottom: 0 !important;
    padding-right: 45px !important;
    min-width: 0 !important;
    text-align: left !important;
    display: block !important;
    line-height: 150%;
    clear: both;
    color: rgba(72, 65, 65, 1);
    font-family: "Titillium Web", "Titillium Web Fallback";
}

.socials-wrapper .socials-container {
    display: inline-block;
    max-width: 100%;
    text-align: inherit;
    unicode-bidi: isolate;
    color: rgba(72, 65, 65, 1);
    font-family: "Titillium Web", "Titillium Web Fallback";

}

.socials-row {
    margin: 0;
    padding: 0;
    gap: 8px;
    display: flex;
}

.socials-row .social-icon {
    text-decoration: none;
    margin: 0;
    cursor: pointer;
    word-wrap: break-word; 
}

.socials-wrapper {
    display: flex;
    justify-content: center;
}

.social-icon {
    width: 27px;
    height: 27px;
    border-radius: 50%;
    display: flex;
    justify-content: center !important;
    align-items: center;
    text-decoration: none;
    background-color: #ec1c2dc7 !important;
    color: white;
    font-size: 14px;
    text-align: center;
    transition: all .2s ease-out !important;
    will-change: scale;
    outline-offset: 5px;

    i {
        justify-self: center;
    }

    &:hover {
        transform: scale(1.15)
    }
}



.contactbar-wrapper .contactbar-container .contactbar-column-2 {
    color: rgba(72, 65, 65, 1);
    font-family: "Titillium Web", "Titillium Web Fallback";
    min-height: 100px;
    padding-left: 0.75%;
    padding-right: 0.75%;
    position: relative;
    width: 16.66667%;
    display: inline-block;
    vertical-align: top;
}

.contactbar-wrapper .contactbar-container .contactbar-column-3 {
    margin-left: 0 !important;
    padding-top: 0 !important;
    padding-left: 0 !important;
    padding-bottom: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    margin-right: 0 !important;
    padding-right: 0 !important;
    color: rgba(72, 65, 65, 1);
    font-family: "Titillium Web", "Titillium Web Fallback";
    position: relative;
    width: 16.66667%;
    display: inline-block;
    vertical-align: top;
    word-wrap: break-word;
}

.contactbar-wrapper .contactbar-container .contactbar-column-4 {
    color: rgba(72, 65, 65, 1);
    font-family: "Titillium Web", "Titillium Web Fallback";
    min-height: 100px;
    padding-right: 0;
    padding-left: 1.5%;
    position: relative;
    width: 41.66667%;
    display: inline-block;
    vertical-align: top;
}

.logo-wrapper {
    transition: height .2s ease, background-color .2s ease, padding .2s ease, width .2s ease, margin .2s ease, top .2s ease, transform .2s ease;
    float: none !important;
    top: 0 !important;
    left: 0 !important;
    position: relative !important;
    padding-top: 25px !important;
    padding-left: 25px !important;
    padding-bottom: 5px !important;
    padding-right: 25px !important;
    min-width: 0 !important;
    text-align: center !important;
    display: block !important;
    margin-right: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    height: auto !important;
    margin-left: 0 !important;
    color: rgba(72, 65, 65, 1);
    font-family: "Titillium Web", "Titillium Web Fallback";
    justify-content: center !important;

    a {
        vertical-align: inherit;
        font-style: italic;
        text-align: center;
        font-family: "Titillium Web", "Titillium Web Fallback";
        word-wrap: break-word;

        img {
            transition: height .2s ease, background-color .2s ease, padding .2s ease, width .2s ease, margin .2s ease, top .2s ease, transform .2s ease;
            width: auto;
            max-height: 85px;
            min-height: 85px;
            display: inline-block;
            aspect-ratio: auto 0 / 0;
            font-style: italic;
            text-align: center;
            cursor: pointer;
            background-color: rgb(255, 255, 255);
            border-radius: 50%;
            border: 3px solid #ec1c2dc7;
            will-change: transform;

            &:hover {
                transform: scale(1.06)
            }
        }
    }
}


.contactbar-wrapper .contactbar-container .contactbar-column-3 .years-wrapper {
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    bottom: -1.2rem;
    text-align: center;
    position: absolute;
    width: 100% !important;
    display: flex;
    justify-content: center;

    p {
        margin: 0;
        padding: 0;
        color: white;
        font-size: 22px;
        text-wrap: nowrap;
        font-weight: 600;
        width: fit-content;
        font-family: 'Georgia';
        /* Add multiple text-shadow layers for a more prominent outline */

    }
}