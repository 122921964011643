.past-worship-sessions-wrapper {
    display: flex;
    max-width: 960px;
    position: relative;
    margin: 0 auto;
    width: 100%;
    text-align: center;
    align-items: center;
    font-family: "Titillium Web", "Titillium Web Fallback";
    justify-content: center;
    padding-top: 3rem;
    flex-wrap: wrap;
    gap: 1.5rem;

    h4 {
        color: rgba(72, 65, 65, 1) !important;
        font-size: 22px;
        margin: 0;
        margin-bottom: 1rem;
        font-weight: 600;
    }
}

.past-worship-sessions-wrapper.admin {
    padding-top: 0;
    background-color: #f7f7f7;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border: 1px solid #ccc;
    color: #333;

    .past-session-wrapper {
        margin: 1.5rem;
    }
}

.past-session-wrapper {
    width: fit-content;
    text-decoration: none;
    color: initial;
}

.go-past-session {
    color: black;
    padding: 0.3rem 1rem;
    font-family: "Titillium Web", "Titillium Web Fallback";
    font-size: larger;
    transition: all 0.2s ease-out;
    text-decoration: none;
    font-weight: 600;
    transition: color 0.2s ease-out;
    margin: 0;
}


.past-worship-sessions-wrapper.admin .past-session:hover {
    border-color: initial !important;
    box-shadow: initial !important;
    border: 1px solid rgba(158, 158, 158, 0.5) !important;

    h3 {
        color: initial !important;
    }
}


.past-session {
    background-color: rgb(255, 255, 255);
    backdrop-filter: blur(4px);
    border: 1px solid rgba(158, 158, 158, 0.5);
    padding: 2rem;
    border-radius: 1rem;
    padding: 1rem;
    padding-bottom: 2rem;
    transition: border-color 0.2s ease-out, box-shadow 0.2s ease-out;
    


    h3 {
        font-size: 28px;
        margin-bottom: 0.5rem;
        color: rgba(72, 65, 65, 1) !important;
        line-height: 100%;
        font-weight: 600;
        transition: color 0.2s ease-out;
    }

    h4 {
        font-size: 22px;
        margin-bottom: 0.5rem;
        color: rgba(72, 65, 65, 1) !important;
        line-height: 100%;
        font-weight: 500;
    }

    h5 {
        font-size: 15px;
        margin-top: 0.5rem;
    }

    &:hover {
        border-color: #750b14 !important;
        box-shadow: rgba(0, 0, 0, 0.158) 0 2px 5px 0 !important;

        h3 {
            color: #a70f1c !important;
            
        }

        .go-past-session {
            visibility: visible;
            opacity: 1;
            color: #a70f1c !important; 
        }
    }

}
