.page-wrapper.baptism .text-content-section:last-of-type {
    .section-content-wrapper .section-image-placeholder {
        justify-content: center;
    }
}

.vbs-date-wrapper {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: start;
    font-weight: 500;
    padding-bottom: 2rem;
    font-size: 1.2rem;
    color: rgba(72, 65, 65, 1);
    font-family: "Titillium Web", "Titillium Web Fallback";
    flex-wrap: wrap;
}

.vbs-date-wrapper .vbs-icon-wrapper {
    padding: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    background-color: #a70f1c !important;
    color: white;
    border-radius: 1rem;
    font-size: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.vbs-when {
    margin-left: 0 !important;
    padding-top: 2px !important;
    padding-left: 0 !important;
    padding-bottom: 2px !important;
    margin-bottom: 8px !important;
    margin-right: 0 !important;
    padding-right: 0 !important;
    color: #a70f1c !important;
    direction: ltr;
    font-family: "Titillium Web", "Titillium Web Fallback";
    font-weight: 700;
    font-style: normal;
    text-decoration: none;
    font-size: 30px;
    text-align: left;
    margin-top: 1rem;

}



.scouts-wrapper {
    display: flex;
    justify-content: space-between;
    color: rgba(72, 65, 65, 1);
    font-family: "Titillium Web", "Titillium Web Fallback";
    font-size: 1.1rem;

    img, span {
        width: 80%;
    }
    
}

.scouts-wrapper .scout-wrapper {
    width: 46%;
    display: flex;
    flex-direction: column;
    align-items: center;
}