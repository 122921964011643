.page-wrapper .page-content-section {
    padding-top: 30px !important;
    padding-bottom: 80px !important;
    margin-left: 0 !important;
    padding-left: 45px !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    margin-right: 0 !important;
    padding-right: 45px !important;
    width: auto !important;
}

@media (max-width: 1050px) {

    .info-icons-wrapper {
        flex-direction: column !important;
        gap: 2rem;
    }

    .info-icons-wrapper .info-pillar {
        flex-direction: row;
        width: 100% !important;
        max-width: 100% !important;
    }

    .page-wrapper .page-content-section .section-content-wrapper .section-image-wrapper {
        width: 100% !important;
    }

  }

.page-wrapper .page-content-section .section-content-wrapper {
    display: flex;
    max-width: 960px;
    position: relative;
    margin: 0 auto;
    width: 100%;
    text-align: center;
    box-sizing: border-box;
}

.page-wrapper .page-content-section .section-content-wrapper .section-text-content h2:first-of-type {
    margin-top: 0 !important;
}

.page-wrapper .page-content-section .section-content-wrapper .section-text-content {
    opacity: 1;
    transform: translate3d(0%, 0%, 0px);
    visibility: visible !important;
    transition-delay: unset !important;
    transition-duration: unset !important;
    animation-delay: unset !important;
    animation-duration: unset !important;
    transition-property: none !important;
    padding-right: 20px !important;
    margin-left: 0 !important;
    padding-top: 0 !important;
    padding-left: 0 !important;
    padding-bottom: 10px !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    margin-right: 0 !important;
    color: rgba(72, 65, 65, 1);
    font-family: "Titillium Web", "Titillium Web Fallback";
    display: inline-block;
    vertical-align: top;
    position: relative;
    width: 50%;
    box-sizing: border-box;

    h2 {
        padding-top: 2px !important;
        padding-left: 0 !important;
        padding-bottom: 2px !important;
        padding-right: 0 !important;
        line-height: 150% !important;
        color: #a70f1c;
        direction: ltr;
        font-family: "Titillium Web", "Titillium Web Fallback";
        font-weight: 700;
        font-style: normal;
        text-decoration: none;
        text-align: left;
        margin: 8px 0;

        display: block;
        unicode-bidi: isolate;
        font-size: 30px;
    }
}

.page-wrapper .page-content-section .section-content-wrapper .section-text-content .section-text-block {
    margin: 0;
    padding: 0;
    display: initial;
    font-weight: bold;
    color: rgba(72, 65, 65, 1);
    font-family: "Titillium Web", "Titillium Web Fallback";
    font-size: 18px;
    text-align: left;

    p {
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        unicode-bidi: isolate;
        color: rgba(72, 65, 65, 1);
        font-family: "Titillium Web", "Titillium Web Fallback";
    }
}

.page-wrapper .page-content-section .section-content-wrapper .section-text-content .info-text-wrapper {
    float: none !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    position: relative !important;
    height: auto !important;
    padding-top: 2px !important;
    padding-left: 0 !important;
    padding-bottom: 2px !important;
    max-width: calc(100% - 0px) !important;
    padding-right: 0 !important;
    min-width: 25px !important;
    display: block !important;
    color: rgba(72, 65, 65, 1);
    font-family: "Titillium Web", "Titillium Web Fallback";
    line-height: 150%;
    text-align: left;
    margin: 8px 0;

    p {
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        unicode-bidi: isolate;
        font-weight: normal;
        color: rgba(72, 65, 65, 1);
        font-family: "Titillium Web", "Titillium Web Fallback";
        text-align: left;
        font-size: 16px;

        a {
            color: #a70f1c;
            transition: color 0.1s ease, font-weight 0.1s ease-out;

            &:hover {
                color: black;
                font-weight: bold;
            }
        }
    }

    h3 {
        color: #750b14;
        margin-bottom: 0.5rem;
    }
}


.text-separator {
    width: 100% !important;
    margin-left: 0 !important;
    padding-top: 0 !important;
    padding-left: 0 !important;
    padding-bottom: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    margin-right: 0 !important;
    padding-right: 0 !important;
    max-width: 100%;
    vertical-align: top;
    box-sizing: border-box;

    hr {
        background: none !important;
        height: 0 !important;
        background-color: transparent !important;
        background-size: initial !important;
        min-height: 0 !important;
        border-color: currentColor !important;
        border-width: 1px !important;
        border-top-style: dotted !important;
        color: rgb(99, 97, 96) !important;
        display: block;
        margin: 10px 0;
        border: none;
        margin-block-start: 0.5em;
        margin-block-end: 0.5em;
        margin-inline-start: auto;
        margin-inline-end: auto;
        unicode-bidi: isolate;
        overflow: hidden;
        text-align: center;
    }
}

.page-wrapper .page-content-section .section-content-wrapper .section-image-wrapper {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 0px;
    padding-right: 0px;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    min-height: 100px;
    position: relative;
    width: 50%;
    display: inline-block;
    vertical-align: top;
    text-align: center;

    .image-placeholder {
        height: 329px !important;
        float: none !important;
        top: 0 !important;
        left: 0 !important;
        width: 300px !important;
        position: relative !important;
        padding-top: 0 !important;
        padding-left: 0 !important;
        padding-bottom: 0 !important;
        margin-right: 0 !important;
        margin-left: 107px !important;
        max-width: calc(100% - 107px) !important;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        padding-right: 0 !important;
        min-width: 25px !important;
        text-align: center !important;
    }
}

.info-icons-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.info-icons-wrapper .info-pillar {
    max-width: 50%;
    display: flex;
    align-items: center;
    text-align: left;
    gap: 0.5rem
}

.info-icons-wrapper .info-pillar.general {
    max-width: 100%;
}

.info-icons-wrapper .info-pillar .info-icon {
    display: flex;
    padding: 0.5rem;
    height: fit-content;
    font-size: 2rem;
    background-color: #a70f1c;
    border-radius: 20%;
    color: white;
    
}